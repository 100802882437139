import React, { useState } from 'react'
import { IoTrashOutline } from "react-icons/io5";
import { IoFilterOutline } from "react-icons/io5";
const Fillter = ({children,actionFilter,restart }) => {
    


return (
    <div className='border border-solid rounded-t-md border-[#0d406b] grid grid-flow-row mb-4'>
        <header className='bg-[#0d406b] text-right text-white font-semibold text-[20px] p-2 font-sans  rounded-t-lg border'>
            סינון
        </header>
        <div className='p-6 bg-white'>
            {children}
            <div className='flex justify-end mt-2'>
                <button className='bg-[#1e8e2b] border border-[#1a7925] text-white w-fit h-fit flex justify-self-end self-center p-2 px-3 rounded-lg' onClick={actionFilter}>
                    סינון
                    <IoFilterOutline size={16}/>
                    
                    </button>

                <button className='bg-[#1e8e2b] border border-[#1a7925] text-white w-fit h-fit flex justify-self-end self-center p-2 px-3 rounded-lg' onClick={restart} >
                    נקה
                    <IoTrashOutline size={16}/></button>
            </div>
        </div>

    </div>
)
}

export default Fillter