import React from 'react'
import homeImage from '../assets/image_home_Page.jpg'
// import homeImage from '../assets/image_home_Page.png'


const HomePage = () => {
    return (
    <div className='bg-[#f3f3f3] p-4 ' >
        <div className='bg-white w-full p-4 flex justify-center '>
                <img src={homeImage} alt='image_home_Page' className=" w-[70%] "/>

        </div>
        

    </div>
  )
}

export default HomePage