import React from 'react'
import Modall from './Modall'
import { useAuth } from '../Context/AuthProvider'
import { useNavigate } from 'react-router-dom';
import Cookies from "universal-cookie"

const ModalLogut = ({showModal,handleColse}) => {
  // const { logout } = useAuth()
  const navigate = useNavigate();
  const logout = async() => 
    {
      const cookies = new Cookies();
        // setSite_id(null)
        // cookies.remove('save_site')

        // const refershToken = cookies.get('bezkoder-jwt-refresh')
        // console.log(refershToken)
        // const {data} = await axios.post('signout' , {"token":refershToken});
        cookies.remove('bezkoder-jwt-refresh')
        cookies.remove('role')
        // axios.defaults.headers.common['Authorization'] = null;
        // console.log(data?.message)
        // cookies.remove('wonderPush_token')
        // navigate('/login');
        window.location.href = '/login';
    }
  return (
    <Modall showModal={showModal}>
        <div className=' w-full divide-y-2  divide-slate-500'>
        <div className='w-full bg-[#0d406b] flex justify-end'>
          <button  className='p-1 px-2 m-3 border bg-slate-500 hover:bg-slate-200' onClick={handleColse}
            // setShowModal(false)

             >x</button>
        </div>
        <div className='w-full p-5 flex flex-row justify-end '>
          <p className='w-full text-center'>?האם אתה בטוח שברצונך להתנתק</p>

        </div>

        <div className='w-full p-2   flex justify-center'>
          <button className='border bg-red-500 hover:bg-red-300 p-1 px-3 rounded-lg' onClick={()=>{
            logout()
            //   if(nameclass!=""){
            //     action(nameclass)
            //     setShowModal(false)
            //   }
    
              
          }}>התנתק</button>
          {/* <button className='border bg-slate-700 hover:bg-slate-500 p-1 px-3'  onClick={() => {
            reset()
            setShowModal(false)}}>בטל</button> */}
        </div>
      </div>

    </Modall>
  )
}

export default ModalLogut