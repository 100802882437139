import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Modall from '../Modall'
const GET_CLASS_EMPLOYEE = 'admin/setting/addEmployee'
const GET_CLASS_EMPLOYEES = 'admin/setting/employees'
const GET_CLASS_URL = 'admin/setting/department'
const GET_JOBS_URL = 'admin/setting/job'
const ModalNewUser = ({ showModal, children, handleColse, getDepartment, row = "" }) => {
    const [fromData, setFormData] = useState(!row?{
        "email": "",
        "phone": "",
        "department": "",
        "job": "",
        "roleName": ""
    }:
    {
        "email": row?.email,
        "phone": row?.phone,
        "department": row?.department?.description,
        "job": row?.job.description,
        "roleName": row?.group
    }
)
console.log(fromData)
    const [arrRole, setArrRole] = useState([])
    const [arrClass, setArrClass] = useState([])
    const [error, setError] = useState("")

    const getDataInfo = async () => {
        await axios.get(GET_CLASS_URL).then(response => {
            if (response.status == 200) { setArrClass(response.data) }
        })
        await axios.get(GET_JOBS_URL).then(response => {
            if (response.status == 200) { setArrRole(response.data) }
        })
    }

    useEffect(() => { getDataInfo() }, [])
    useEffect(() => { console.log(fromData) }, [fromData])


    // const arrGroup = ["Power User", "Advanced User", "Basic User"]
    const arrGroup = ["POWER", "ADVANCED", "BASIC"]
    // useEffect(() => {
    //     if (row) {
    //         setnameRole(row.description)
    //     }
    // }, [])

    const handleEditUser = async (id) => {

        await axios.put(`${GET_CLASS_EMPLOYEES}/${id}`, JSON.stringify({...fromData}), {
            headers: { 'Content-Type': 'application/json' }
        }).then(response => {
            if (response.status == 200) {
                getDepartment()
                handleColse()
                console.log("Edit")
            }
        })
    }
    const addUser = async () => {
        const response = await axios.post(GET_CLASS_EMPLOYEE, JSON.stringify({...fromData} ), {
            headers: { 'Content-Type': 'application/json' }
        })
        console.log(response)
        if (response?.status == 201) {
            getDepartment()
            handleColse()
        }
        else {
            console.log("error add class")
        }
    }


    const handleCheck = (name) => {
        if (!name) {
            setError("תיאור התפקיד ריק")
        }
        else {
            setError("")
        }
        // setnameRole(name)

    }
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...fromData,
            [name]: value
        });
    };


    return (
        <Modall showModal={showModal}>
            <div className=' w-full divide-y-2  divide-slate-500'>
                <div className='w-full bg-[#0d406b] flex justify-end'>
                    <button className='p-1 px-2 m-3 border bg-slate-500 hover:bg-slate-200' onClick={() => {
                        handleColse()
                    }} >x</button>
                </div>
                <form className='w-full p-5 flex flex-col items-end '>
                    {/* {children} */}
                    {/* <div className='w-full  pb-0 p-3 flex justify-end '>

                        <input type="text" className='border w-[70%] text-right' value={fromData} onChange={(e) => { handleCheck(e.target.value) }} />
                        <label className='flex px-4  w-[20%]'><p className='text-red-600'>*</p>שם מלא</label>

                    </div> */}
                    <div className='w-full  pb-0 p-3 flex justify-end '>

                        <input type="text" className='border w-[70%] text-right' name="email" value={fromData.email} onChange={handleChange} />
                        <label className='flex px-4 w-[20%]'><p className='text-red-600'>*</p>דוא"ל</label>

                    </div>
                    <div className='w-full  pb-0 p-3 flex justify-end '>

                        <input type="text" className='border w-[70%] text-right' name="phone" value={fromData.phone} onChange={handleChange} />
                        <label className=' w-[20%] flex px-4'><p className='text-red-600'>*</p>טלפון</label>

                    </div>

                    <div className='w-full  pb-0 p-3 flex justify-end '>

                        <select className='border w-[70%] text-right' name="department" value={fromData.department} onChange={handleChange}>
                            <option value="" disabled>
                                Select an option
                            </option>
                            {arrClass?.map((items) => (<option value={items.description}>{items.description}</option>))}
                        </select>
                        <label className='flex px-4 w-[20%]'><p className='text-red-600'>*</p>מחלקה</label>

                    </div>
                    <div className='w-full  pb-0 p-3 flex justify-end '>

                        <select className='border w-[70%] text-right' name="job" value={fromData.job} onChange={handleChange}>
                            <option value="" disabled>
                                Select an option
                            </option>
                            {arrRole.map((items) => (<option value={items.description}>{items.description}</option>))}
                        </select>
                        <label className='flex px-4 w-[20%]' ><p className='text-red-600'>*</p>תפקיד</label>

                    </div>
                    <div className='w-full  pb-0 p-3 flex justify-end '>
                        <select className='border w-[70%] text-right' name="roleName" value={fromData.roleName} onChange={handleChange}>
                            <option value="" disabled>
                                Select an option
                            </option>
                            {arrGroup.map((items) => (<option value={items}>{items}</option>))}
                        </select>
                        <label className='flex px-4 w-[20%]'><p className='text-red-600'>*</p>קבוצה</label>

                    </div>
                    {error && <p className='text-red-500 text-center'>{error}</p>}


                </form>

                <div className='w-full p-2   flex justify-end'>
                    <button className='border bg-green-500 hover:bg-green-300 p-1 px-3' onClick={() => {
                       
                            row ? handleEditUser(row.id) : addUser()
                     


                    }}>שמור</button>
                    <button className='border bg-slate-700 hover:bg-slate-500 p-1 px-3' onClick={() => {
                        // reset()
                        // setShowModal(false)
                        handleColse()
                    }}>בטל</button>
                </div>
            </div>

        </Modall>)
}

export default ModalNewUser