import React, { useEffect, useState ,useRef} from 'react';
import './TimeInput.css';

const TimeInput = ({ setTime ,setOpen,setFormattedTime}) => {
    // Initialize the state for hours, minutes, and seconds
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const containerRef = useRef(null);
    // Utility functions to handle increment/decrement
    const increment = (type) => {
      
        if (type === 'hours') {
            setHours((prev) => (prev + 1) % 24); // Wrap around to 0 after 23
        } else if (type === 'minutes') {
            setMinutes((prev) => (prev + 1) % 60); // Wrap around to 0 after 59
        } else if (type === 'seconds') {
            setSeconds((prev) => (prev + 1) % 60); // Wrap around to 0 after 59
        }

    };

    const handleClickOutside = (event) => {
        if (containerRef.current && !containerRef.current.contains(event.target)) {
            // setOpen(false); // Close the component
        }
      };

    const decrement = (type) => {
        if (type === 'hours') {
            setHours((prev) => (prev === 0 ? 23 : prev - 1)); // Wrap around to 23 from 0
        } else if (type === 'minutes') {
            setMinutes((prev) => (prev === 0 ? 59 : prev - 1)); // Wrap around to 59 from 0
        } else if (type === 'seconds') {
            setSeconds((prev) => (prev === 0 ? 59 : prev - 1)); // Wrap around to 59 from 0
        }
        // setTime(Date(hours, minutes, seconds))
    };
    useEffect(()=>{
        const data = new Date()
        data.setHours(hours)
        data.setMinutes(minutes)
        data.setSeconds(seconds)

        // setFormattedTime(`${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`)
        // setTime(data)
    },[hours,minutes,seconds])
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, []);
    return (
        <div  ref={containerRef} className="time-input-container absolute top-full" >
            {/* Hours */}
            <div className="time-input-box">
                <button className="arrow-button" onClick={() => increment('hours')}>
                    ▲
                </button>
                <input
                    type="text"
                    placeholder='HH'
                    //   value={String(hours).padStart(2, '0')}
                    onChange={(e) => setHours(Math.min(23, Math.max(0, Number(e.target.value))))}
                    className="time-input-field"
                />
                <button className="arrow-button" onClick={() => decrement('hours')}>
                    ▼
                </button>
            </div>

            <span>:</span>

            {/* Minutes */}
            <div className="time-input-box">
                <button className="arrow-button" onClick={() => increment('minutes')}>
                    ▲
                </button>
                <input
                    type="text"
                    placeholder='mm'
                    value={String(minutes).padStart(2, '0')}
                    onChange={(e) => setMinutes(Math.min(59, Math.max(0, Number(e.target.value))))}
                    className="time-input-field"
                />
                <button className="arrow-button" onClick={() => decrement('minutes')}>
                    ▼
                </button>
            </div>

            <span>:</span>

            {/* Seconds */}
            <div className="time-input-box">
                <button className="arrow-button" onClick={() => increment('seconds')}>
                    ▲
                </button>
                <input
                    type="text"
                    placeholder='SS'
                    value={String(seconds).padStart(2, '0')}
                    onChange={(e) => setSeconds(Math.min(59, Math.max(0, Number(e.target.value))))}
                    className="time-input-field"
                />
                <button className="arrow-button" onClick={() => decrement('seconds')}>
                    ▼
                </button>
            </div>
        </div>
    );
};

export default TimeInput



// const [hours, setHours] = useState(0);
// const [minutes, setMinutes] = useState(0);
// const [seconds, setSeconds] = useState(0);

// // Utility functions to handle increment/decrement
// const increment = (type) => {
//   if (type === 'hours') {
//     setHours((prev) => (prev + 1) % 24); // Wrap around to 0 after 23
//   } else if (type === 'minutes') {
//     setMinutes((prev) => (prev + 1) % 60); // Wrap around to 0 after 59
//   } else if (type === 'seconds') {
//     setSeconds((prev) => (prev + 1) % 60); // Wrap around to 0 after 59
//   }
// };

// const decrement = (type) => {
//   if (type === 'hours') {
//     setHours((prev) => (prev === 0 ? 23 : prev - 1)); // Wrap around to 23 from 0
//   } else if (type === 'minutes') {
//     setMinutes((prev) => (prev === 0 ? 59 : prev - 1)); // Wrap around to 59 from 0
//   } else if (type === 'seconds') {
//     setSeconds((prev) => (prev === 0 ? 59 : prev - 1)); // Wrap around to 59 from 0
//   }
// };

// return (
//   <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
//     {/* Hours input */}
//     <div>
//       <button onClick={() => increment('hours')}>▲</button>
//       <div style={{ textAlign: 'center', width: '40px' }}>
//         {String(hours).padStart(2, '0')}
//       </div>
//       <button onClick={() => decrement('hours')}>▼</button>
//     </div>

//     <span>:</span>

//     {/* Minutes input */}
//     <div>
//       <button onClick={() => increment('minutes')}>▲</button>
//       <div style={{ textAlign: 'center', width: '40px' }}>
//         {String(minutes).padStart(2, '0')}
//       </div>
//       <button onClick={() => decrement('minutes')}>▼</button>
//     </div>

//     <span>:</span>

//     {/* Seconds input */}
//     <div>
//       <button onClick={() => increment('seconds')}>▲</button>
//       <div style={{ textAlign: 'center', width: '40px' }}>
//         {String(seconds).padStart(2, '0')}
//       </div>
//       <button onClick={() => decrement('seconds')}>▼</button>
//     </div>
//   </div>
// );
// };
