import React, { useState } from 'react'

import MoreOptionsCard from '../../../../ui/MoreOptionsCard'
import TimeInput from '../../../../ui/TimeInput'

const NewActivityday = ({handleColse}) => {
    const [open,setOpen]=useState(false)
    const [time,setTime]=useState(null)
    const [formattedTime, setFormattedTime] = useState("");
    return (
        <MoreOptionsCard handleColse={handleColse}>
            <div>
            <div className='grid grid-cols-10 'dir='rtl'>
                <div className='col-span-3' >תאריך</div>
                <input type='date'className='col-span-1 text-right'/>
                         
            </div>
            <div className='grid grid-cols-10 ' dir='rtl'>
                <label className='col-span-3'>שעת התחלה</label>
                <div className='col-span-1 text-right relative' >
                <input 
        type="text" 
        // value={formattedTime} 
        readOnly 
        onClick={() => setOpen((prev) => !prev)} 
        // className="time-input-display"
      />
                {open&&<TimeInput setTime={setTime} setOpen={setOpen} setFormattedTime={setFormattedTime}/>}
                </div>  
            </div>
            <div className='grid grid-cols-10 ' dir='rtl'>
                <div className='col-span-3'>שעת סיום</div>
                <input 
        type="text" 
        // value={formattedTime} 
        readOnly 
        onClick={() => setOpen((prev) => !prev)} 
        // className="time-input-display"
      />
                {open&&<TimeInput setTime={setTime} setOpen={setOpen} setFormattedTime={setFormattedTime}/>}
            </div>
            </div>
        </MoreOptionsCard>
    )
}

export default NewActivityday