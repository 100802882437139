import React, { useState ,useEffect} from 'react'
import DataTable from 'react-data-table-component'
// import Card from '../Card'
import { BsPencil } from "react-icons/bs";
import { IoTrashOutline } from "react-icons/io5";
import axios from 'axios'
import { HeaderTable } from '../HeaderTable';
const GET_JOBS_URL='admin/setting/job'

const Groups = () => {
  
  const arrGroup=["Power User","Advanced User","Basic User","Member"]
  
const columns = [
  {
    name: "פעולה",
    
    // cell:row=><div><button  className=' border border-gray-500 p-2 opacity-15 hover:opacity-100' onClick={()=>{alert(row?.id)}}><BsPencil/></button> 
    // <button title="מחיקה" className=' border border-gray-500 ml-2 p-2 opacity-15 hover:opacity-100'onClick={()=>{handleDeteleJob(row?.id)}}><IoTrashOutline/></button> </div>,
    style: {
      fontSize: '16px',
      justifyContent: 'center',
      
    },
    grow:1,
      
    
},
  {
    name: "שם קבוצה",
    grow: 6,
    selector:(row)=>row,
    style: {
      fontSize: '16px',
      justifyContent: 'center',
      width: '100px'},
      sortable: true,
},



];



const customStyles = {
header: {
  style: {

    justifyContent: 'center',
    
  },
},
id:{
  style:{
    '&:2': {backgroundColor: '#EEEEEE' },}},
headRow: {
  style: {
    justifyContent: 'center',
    borderTopStyle: 'solid',
    borderTopWidth: '1px',
    // borderTopColor: defaultThemes.default.divider.default,
  },
},
headCells: {
  style: {
      
      justifyContent: 'center',
      borderRightStyle: 'solid',
      borderRightWidth: '1px',
      // borderRightColor: defaultThemes.default.divider.default,
    
  },
},
cells: {
  style: {
    '&:not(:last-of-type)': {
      
      borderRightStyle: 'solid',
      borderRightWidth: '1px',
      // borderRightColor: defaultThemes.default.divider.default,
    },
  },
},
 rows: {
style: {
  backgroundColor: '#FFFFFF',
  '&:active': {
    backgroundColor: '#EEEEEE',
  },
},
},
}



  const [loading,setloading]=useState(false)
  return (
    <div className='w-full p-3'>
      
      <HeaderTable  active={"קבוצות"} nameTab={"קבוצה חדשה"}></HeaderTable>
      <DataTable columns={columns}  pagination customStyles={customStyles} data={arrGroup} progressPending={loading} className='border' >

        
</DataTable>
    </div>
  )
}

export default Groups