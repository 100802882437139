import React, { useState,useEffect } from 'react'
import axios from 'axios'
import Fillter from '../../Fillter'
import Select from "react-select"
const GET_Filter = 'admin/qualification/init_filter_instructor'

const GuidesFilter = ({ setDataFillter, setCurrentPage }) => {
    const [datafiler, setDatafiler] = useState("");
   
    const getFilter = async () => {

        await axios.get(GET_Filter, {
        })
            .then(response => {
                setDatafiler(response?.data)

            })

    }
    useEffect(() => {
        getFilter()
    }, [])


    const [filters, setFilters] = useState({
        'name': '',
        'identity': '',
        'phone':'',
    })

    const restart = () => {
        setFilters(
            {
                'name': '',
                'identity': '',
                'phone':'',
            }

        )
        setDataFillter(
            {
                'name': '',
                'identity': '',
                'phone':'',
            }
        )
        setCurrentPage(1)
    }
    const handleChange = (e,name) => {
        
        setFilters({
            ...filters,
            [name]: e?.value
        });
    }
    const handleClickFilter = () => {
        
        setDataFillter(filters)
        setCurrentPage(1)
    }

   

    return (
        <Fillter actionFilter={handleClickFilter} restart={restart}>
            <div className='grid grid-cols-3 '>


            {/* <Select className='border border-black w-2/3 text-right '
                            type="text"
                            id="pay_id"
                            name="pay_id"
                            // value={dataFillter.pay_id_labal?
                            //     { value: dataFillter.pay_id_labal, label: dataFillter.pay_id_labal }:null}
                            onChange={(e)=>handleChange2(e,'pay_id',"pay_id_labal")}
                            options={datafiler?.names?.map((item) => { return { value: item, label: item } })}
                            placeholder="...חפש"
                            isClearable
                            isSearchable
                            maxMenuHeight={100} /> */}




                <div className='flex justify-end items-center'>
                    <Select className=' w-2/3 text-right'
                        type="number"
                        id="phone"
                        name="phone"
                        value={filters?.phone?{value:filters.phone,label:filters.phone}:null}
                        onChange={(e)=>handleChange(e,'phone')}
                        options={datafiler?.phones?.map((item) => { return { value: item, label: item } })}
                        placeholder="...חפש"
                            isClearable
                            isSearchable
                            maxMenuHeight={100}  />

                    <label className='ml-3' htmlFor="phone">טלפון</label>
                </div>
                <div className=' flex justify-end items-center '  >
                    <Select className=' w-2/3 text-right'
                        type="text"
                        id="identity"
                        name="identity"
                        value={filters?.identity?{value:filters.identity,label:filters.identity}:null}
                        onChange={(e)=>handleChange(e,'identity')}
                        options={datafiler?.identitys?.map((item) => { return { value: item, label: item } })}
                        
                        placeholder="...חפש"
                            isClearable
                            isSearchable
                            maxMenuHeight={100}  />

                    <label className='ml-3 ' htmlFor="identity">ת.ז</label>

                </div>
                <div className=' flex justify-end items-center '  >
                    <Select className='w-2/3 text-right  '
                        type="text"
                        id="name"
                        name="name"
                        value={filters?.name?{value:filters.name,label:filters.name}:null}
                        onChange={(e)=>handleChange(e,'name')}
                        options={datafiler?.names?.map((item) => { return { value: item, label: item } })}
                        
                        placeholder="...חפש"
                            isClearable
                            isSearchable
                            maxMenuHeight={100}  
                    />
                    <label className='ml-3 ' htmlFor="name">שם מלא</label>

                </div>
            </div>
        </Fillter>
    )
}
export default GuidesFilter