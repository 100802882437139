import React, { useState } from 'react'
import MoreOptionsCard from '../ui/MoreOptionsCard'
import UserMemberOnApp from './subTab_more_options/UserMemberOnApp'
import SubTab from '../SubTab'
import MembersTrainingdays from './subTab_more_options/MembersTrainingdays'

const MoreOptions = ({ handleColse, row }) => {
  const [active, setActive] = useState("משתמש לאתר")
  console.log(row)
  return (
    <MoreOptionsCard handleColse={handleColse}>
      <div className='w-full p-2 flex flex-col  ' style={{ direction: "rtl" }} >
        <h3 className='font-bold underline font-mono text-lg text-center' >{`${row?.firstName} ${row?.lastName}-${row?.number}-${row?.status?.statusDescription}`}</h3>
        <div className='bg-[#f3f3f3] p-4 ' >
        <ul className='flex flex-row  m-0 list-none  '   >
          <SubTab active={active == "משתמש לאתר"} onClick={() => { setActive("משתמש לאתר") }}>משתמש לאתר </SubTab>
          <SubTab active={active == "משתמש לאפליקציה"} onClick={() => { setActive("משתמש לאפליקציה") }}>משתמש לאפליקציה</SubTab>
          <SubTab active={active == "ימי כשירות"} onClick={() => { setActive("ימי כשירות") }}>ימי כשירות</SubTab>
        </ul>
        <div className='bg-white w-full'>
          {active == "משתמש לאתר" && <UserMemberOnApp row={row}/>}
          {active == "משתמש לאפליקציה" && <UserMemberOnApp row={row}  />}
          {active == "ימי כשירות" && <MembersTrainingdays row={row}  />}
          
        </div>
      </div>
    </div>
                        
    </MoreOptionsCard >
  )
}

export default MoreOptions