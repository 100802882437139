import React, { useState } from 'react'
import Card from '../components/Card'
import Class from '../components/adminstration/Class'
import SubTab from '../components/SubTab'
import Roles from '../components/adminstration/Roles'
import Users from '../components/adminstration/Users'
import Products from '../components/adminstration/Products'
import Groups from '../components/adminstration/Groups'
import Paying_customers from '../components/adminstration/Paying_customers'
import ReportTrainingDays from '../components/adminstration/ReportTrainingDays'
import Forms from '../components/adminstration/Forms'

const Adminstration = () => {
    const [active,setActive]=useState("מחלקות")
  return (
    <div className='bg-[#f3f3f3] p-4 ' >
    
    <ul className='flex flex-row-reverse  m-0 list-none  '   >
        <SubTab active={active=="מחלקות"} onClick={()=>{setActive("מחלקות")}}>מחלקות </SubTab>
        <SubTab active={active=="תפקידים"} onClick={()=>{setActive("תפקידים")}}>תפקידים</SubTab>
        <SubTab active={active=="קבוצות"} onClick={()=>{setActive("קבוצות")}}>קבוצות </SubTab>
        <SubTab active={active=="משתמשים"} onClick={()=>{setActive("משתמשים")}}>משתמשים</SubTab>
        <SubTab active={active=="טפסים"} onClick={()=>{setActive("טפסים")}}>טפסים  </SubTab>
        <SubTab active={active=="מוצרים"} onClick={()=>{setActive("מוצרים")}}>מוצרים </SubTab>
        <SubTab active={active=="לקוחות משלמים"} onClick={()=>{setActive("לקוחות משלמים")}}>לקוחות משלמים </SubTab>
        <SubTab active={active=="דוח ימי כשירות"} onClick={()=>{setActive("דוח ימי כשירות")}}>דוח ימי כשירות </SubTab>
    </ul>
    <div className='bg-white w-full '>
        {/* <Class/> */}
        {active=="מחלקות"&&<Class/>}
        {active=="תפקידים"&& <Roles/>}
        {active=="קבוצות"&&<Groups/>}
        {active=="משתמשים"&& <Users/>}
        {active=="טפסים"&& <Forms/>}
        {active=="מוצרים"&& <Products/>}
        {active=="לקוחות משלמים"&& <Paying_customers/>}
        {active=="דוח ימי כשירות"&& <ReportTrainingDays/>}

    </div>

</div>
  )
}

export default Adminstration