import React, { useEffect, useState } from 'react'
import { HeaderTable } from '../../HeaderTable'
import DataTable from 'react-data-table-component'
import axios from 'axios'
const Paying_customer_members = ({row}) => {
    const [fetchData, setFetchData] = useState([])
    const [loading, setloading] = useState(true)
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1)
   const GET_URL = `admin/setting/paying_customer/show/${row?.id}/members`
   


    const getDataTable = async (page) => {
        setloading(true)
        await axios.get(GET_URL, {
        //    
        })
            .then(response => {
                if (response.status == 200) {
                    setFetchData(response?.data)
                    // setdataForfilterScrach(response?.data)
                    // setTotalRows(response.data.totalElements);
                }
            })
        setloading(false)

    }


    useEffect(() => {
        getDataTable(currentPage - 1)
    }, [])

    const columns = [

        {
            name: "פעולות",
            // grow: 2,
            selector: (row) => "",
            style: {
                fontSize: '10px',
                justifyContent: 'center',
                
                // width: '100px'
            },
            sortable: true,
        },
        {
            name: "מספר נ.ש",
            // grow: 2,
            selector: (row) => row?.nsnumber,
            style: {
                fontSize: '10px',
                justifyContent: 'center',
                
                // width: '100px'
            },
            sortable: true,
        },

        {
            name: "ת.ז.",
            cell: row => row?.idNumber,

            style: {
                fontSize: '10px',
                justifyContent: 'center',

            },
            // grow: 2,


        },
        {
            name: "שם משפחה",
            cell: row => row?.lastName,

            style: {
                fontSize: '10px',
                justifyContent: 'center',

            },
            // grow: 2,


        },
        {
            name: "שם פרטי",
            cell: row => row?.firstName,

            style: {
                fontSize: '10px',
                justifyContent: 'center',

            },
            //grow: 2,


        },
        {
            name: "מספר חבר",
            cell: row => row?.number,

            style: {
                fontSize: '10px',
                justifyContent: 'center',

            },
            grow: 2,


        },



    ];



    const customStyles = {
        header: {
            style: {

                justifyContent: 'center',

            },
        },
        id: {
            style: {
                '&:2': { backgroundColor: '#EEEEEE' },
            }
        },
        headRow: {
            style: {
                justifyContent: 'center',
                borderTopStyle: 'solid',
                borderTopWidth: '1px',
                // borderTopColor: defaultThemes.default.divider.default,
            },
        },
        headCells: {
            style: {
fontSize: '10px',
                justifyContent: 'center',
                borderRightStyle: 'solid',
                borderRightWidth: '1px',
                // borderRightColor: defaultThemes.default.divider.default,

            },
        },
        cells: {
            style: {
                '&:not(:last-of-type)': {
                    
                    borderRightStyle: 'solid',
                    borderRightWidth: '1px',
                    // borderRightColor: defaultThemes.default.divider.default,
                },
            },
        },
        rows: {
            style: {
                backgroundColor: '#FFFFFF',
                '&:active': {
                    backgroundColor: '#EEEEEE',
                },
            },
        },
    }









    return (
        <div className='w-full p-3' style={{direction:"ltr"}}>

            <HeaderTable active={"ממונים הרשומים תחת הלקוח"}></HeaderTable>

            <DataTable columns={columns} data={fetchData} pagination
                customStyles={customStyles}
                // paginationServer
                // paginationTotalRows={totalRows}
                // onChangeRowsPerPage={handlePerRowsChange}
                // onChangePage={handlePageChange}
                progressPending={loading} className='border' >

            </DataTable>


        </div>
    
  )
}

export default Paying_customer_members