import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Modall from '../../Modall';
import Select from "react-select"
import { convertDateFormat } from '../../../util/formatsForDate';

const GET_URL = 'admin/qualification/create_Qualification_day'
const GET_Filter = 'admin/qualification/all_Scroll_before_buildQ'

const ModalNewTrainingDays = ({ showModal, children, handleColse, getDepartment, row = "",getDFilter }) => {
    const [previewUrl, setPreviewUrl] = useState('');
    const [formData, setFormData] = useState({
        "clusterId": null,
        "number": "",
        "name": "",
        "productId": null,
        "description": "",
        "startDate": "",
        "lastDateCancellation": "",
        "maximumParticipants": 0,
        "isOnline": true,
        "place": "string",
        "latitude": 0,
        "longitude":0,
        "distanceSignature": 0,
        "statusQualificationId": null,
        "instructorId": null,
        "titleCertificateId": null,
        "days": 0,
        "hours": 0,
        "manualSignatureWindow": true,
        // qulImage: '',
    });
    const [file, setFile] = useState(null)
    const [errors, setErrors] = useState({});

    const handleChangetext = (e) => {
        console.log(e.target)
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };
    const handleChangeData = (e) => {
        console.log(e.target.value)
        const { name, value } = e.target;
        // const date = new Date(value);
        
        // date.setUTCHours(0, 0, 0, 0);

        // // Convert to ISO string and replace 'Z' with '+00:00' for the timezone
        // ;
        // setFormData({
        //     ...formData,
        //     [name]: date.toISOString().replace('Z', '+00:00')
        // });
        setFormData({
            ...formData,
            [name]: convertDateFormat(value)
        });
    };

    
    const handleChange = (selectedOption,name,) => {
        console.log(selectedOption)
        // const { name } = e.name;
        setFormData({
            ...formData,
            [name]: selectedOption?.value
            ,
        })}

    const validate = () => {
        const newErrors = {};
        // if (!formData.fullname) newErrors.fullname = 'שדה חובה';
        // if (!formData.identity) newErrors.identity = 'שדה חובה';
        // if (!formData.phone) newErrors.phone = 'שדה חובה';
        // if (!formData.urlimage) newErrors.file = 'שדה חובה';


        return newErrors;
    };

    const handleSubmit = () => {
        // e.preventDefault();
        const validationErrors = validate();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            // Submit the form data
            console.log(formData)
            addNewTrainingDays()
        }
    };

    // useEffect(() => {
    //     if (row) {
    //         setFormData(
    //             {
    //                 'fullname': row.name,
    //                 'identity': row.identity,
    //                 'phone': row.phone,
    //                 'urlimage': `https://archivebureau-tests.s3.eu-west-3.amazonaws.com/${row.imgSignature}`,
    //             })
    //         setPreviewUrl(`https://archivebureau-tests.s3.eu-west-3.amazonaws.com/${row.imgSignature}`)
    //         // setFile(row.imgSignature)
    //     }
    // }, [])


    const handleFileChange = (event) => {
        console.log(event, event.target.value)
        const { name } = event?.target
        const file1 = event.target.files[0];
        setFile(file1)
        setFormData({
            ...formData,
            [name]: event.target.value
        })
        if (file1) {



            const reader = new FileReader();
            reader.onload = (e) => {
                setPreviewUrl(e.target.result);

            };

            reader.readAsDataURL(file1);


            //  reader.onload = (e) => {
            //     console.log( e.target.result)
            //     setFormData({
            //         ...formData,
            //         'urlimage': e.target.result
            //     });;
            //     reader.readAsDataURL(file1);
            // };

            // Extracting only the file name
            //   setFormData({
            //     ...formData,
            //     [name]: event.target.result
            // });
            //   setImageFileName(file.name);
        }
    };

    // useEffect(() => { console.log(previewUrl) }, [previewUrl])

    const handleEditClass = async (id) => {
        const validationErrors = validate();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            const formData1 = new FormData();
            formData1.append('instructorDetails', JSON.stringify({
                "name": formData.fullname,
                "identity": formData.identity,
                "phone": formData.phone
            }));
            formData1.append('img_Signature', file);
            const response = await axios.put(`${GET_URL}/${id}`, formData1
                ,
                {
                    headers: { 'Content-Type': 'multipart/form-data' }
                })
            if (response.status == 200) {
                // setnamecalss("")
                getDepartment()
                handleColse()
                console.log("Edit")
            }






            // await axios.put(`${GET_URL}/${id}`, JSON.stringify({
            //     "name": formData.name,
            //     "identity": formData.id,
            //     "shippingAddress": formData.shippingAddress,
            //     "postalCode": formData.postcode,
            //     "primaryEmailReceipts": formData.primaryEmailReceipts,
            //     "attendanceBox": 0
            // }), {
            //     headers: { 'Content-Type': 'application/json' }
            // }).then(response => {
            //     if (response.status == 200) {
            //         // setnamecalss("")
            //         getDepartment()
            //         handleColse()
            //         console.log("Edit")
            //     }
            // })
        }
    }

    const addNewTrainingDays = async () => {

        const formData1 = new FormData();
        formData1.append('qulDetails', JSON.stringify(formData
            // "clusterId": 6,
            // "number": "122528",
            // "name": "אירועים המוניים - מורן טולדו - חיפה  - צהריים - 10416261",
            // "productId": 1,
            // "description": "string",
            // "startDate": "2024-12-11 00:00:00",
            // "lastDateCancellation": "2024-12-05 00:00:00",
            // "maximumParticipants": 10,
            // "isOnline": true,
            // "place": "string",
            // "latitude": 30.36,
            // "longitude": 30.41,
            // "distanceSignature": 150,
            // "statusQualificationId": 1,
            // "instructorId": 0,
            // "titleCertificateId": 0,
            // "days": 1,
            // "hours": 3,
            // "manualSignatureWindow": true,
            // 'qulImage': file
        ));
        // formData1.append('qulImage', file);
        const response = await axios.post(GET_URL, formData1

            ,
            {
                headers: { 'Content-Type': 'multipart/form-data' }
            })



      
        console.log(response)
        if (response?.status == 200) {
           
            console.log(formData)
            // setnamecalss("")
            getDFilter()
            getDepartment()
            handleColse()
        }
        else {
            console.log("error add class")
        }
    }
    const [datafiler, setDatafiler] = useState("");
    
    const getFilter = async () => {

        await axios.get(GET_Filter, {
        })
            .then(response => {
                setDatafiler(response?.data)

            })

    }
    useEffect(() => {
        getFilter()
    }, [])

    return (
        <Modall showModal={showModal}>
            <div className=' w-full divide-y-2  divide-slate-500'>
                <div className='w-full bg-[#0d406b] flex justify-end'>
                    <button className='p-1 px-2 m-3 border bg-slate-500 hover:bg-slate-200' onClick={() => {
                        handleColse()
                    }} >x</button>
                </div>


                <form className='w-full p-5 flex flex-col  text-center text-sm'>
                    <div className='w-full pt-2 flex justify-end'>

                        <Select
                            className=' w-1/2 border text-right'
                            placeholder="בחר"
                            options={datafiler?.resClusterQS?.map((item) => { return { value: item.id, label: item.display } })}
                            isClearable
                            isSearchable
                            maxMenuHeight={100} 
                            id="clusterId"
                            name="clusterId"
                            // value={formData?.clusterId}
                            onChange={(e) => handleChange(e, 'clusterId')}
                            
                        />
                        <label htmlFor="clusterId " className='w-[30%]'><p className='ml-2'><span className='text-red-600'>*</span>אשכול</p></label>

                    </div>
                    {errors.fullname && <span className="error">{errors.fullname}</span>}

                    <div className='w-full pt-2 flex justify-end'>

                        <input
                            className='border w-1/2 text-right'
                            type="number"
                            id="number"
                            name="number"
                            value={formData.number}
                            onChange={handleChangetext}
                        />
                        <label htmlFor="number" className='w-[30%]'><p className='ml-2'><span className='text-red-600'>*</span>מספר יום הכשירות</p></label>

                    </div>
                    {errors.identity && <span className="error">{errors.identity}</span>}

                    <div className="w-full pt-2 flex justify-end">

                        <input
                            className='border w-1/2 text-right'
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChangetext}
                        />
                        <label htmlFor="name" className='w-[30%]'><p className='ml-2'><span className='text-red-600'>*</span>שם יום הכשורות</p></label>

                    </div>
                    {errors.phone && <span className="error">{errors.phone}</span>}


                    <div className="w-full pt-2 flex justify-end">

                        <Select
                            className=' w-1/2 border text-right'
                            placeholder="בחר"
                            isClearable
                            isSearchable
                            id="productId"
                            name="productId"
                            options={datafiler?.resProductQS?.map((item) => { return { value: item.id, label: item.desc } })}
                            onChange={(e) => handleChange(e, 'productId')}
                            maxMenuHeight={100}
                       />
                        <label htmlFor="productId" className='w-[30%]'><p className='ml-2'><span className='text-red-600'>*</span>שיוך למוצר</p></label>

                    </div>



                    <div className="w-full pt-2 flex justify-end">

                    <textarea
                            className='border w-1/2 h-20 text-right'
                            type="text"
                            id="description"
                            name="description"
                            // value={}
                            onChange={handleChangetext}
                        />
                        <label htmlFor="description" className='w-[30%]'><p className='ml-2'><span className='text-red-600'>*</span>תיאור</p></label>

                    </div>
                    <div className="w-full pt-2 flex justify-end">

                        <input
                            className='border w-1/2 text-right bg-slate-300'
                            type="Date"
                            id="startDate"
                            name="startDate"
                            onChange={handleChangeData}
                    
                        />
                        <label htmlFor="startDate" className='w-[30%]'><p className='ml-2'><span className='text-red-600'>*</span>תאריך התחלה</p></label>

                    </div>
                    <div className="w-full pt-2 flex justify-end">

                        <input
                            className='border w-1/2 text-right bg-slate-300'
                            type="Date"
                            id="lastDateCancellation"
                            name="lastDateCancellation"
                            onChange={handleChangeData}
                        />
                        <label htmlFor="lastDateCancellation" className='w-[30%]'><p className='ml-2'><span className='text-red-600'>*</span>תאריך אחרון לביטול</p></label>

                    </div>
                    <div className="w-full pt-2 flex justify-end">

                        <input
                            className='border w-1/2 text-right bg-slate-300'
                            type="number"
                            id="maximumParticipants"
                            name="maximumParticipants"
                            value={0}
                            onChange={handleChangetext}
                        />
                        <label htmlFor="maximumParticipants" className='w-[30%]'><p className='ml-2'><span className='text-red-600'>*</span>מספר משתתפים מקסימלי</p></label>

                    </div>
                    <div className="w-full pt-2 flex justify-end">

                        <input
                            className='border  bg-slate-300'
                            type="checkbox"
                            id="isOnline"
                            name="isOnline"
                            // onChange={handleChangetext}
                       
                        />
                        <label htmlFor="isOnline" className='w-[30%]'><p className='ml-2'><span className='text-red-600'>*</span>האם הדרכה מקוונת</p></label>

                    </div>
                    <div className="w-full pt-2 flex justify-end">

                        <input
                            className='border w-1/2  bg-slate-300'
                            
                            id="place"
                            name="place"
                            onChange={handleChangetext}
                            
/>
                        <label htmlFor="place" className='w-[30%]'><p className='ml-2'><span className='text-red-600'>*</span>מיקום</p></label>

                    </div>
                    <div className="w-full pt-2 flex justify-end">

                        <input
                            className='border w-1/2 text-right bg-slate-300'
                            type="number"
                            id="latitude"
                            name="latitude"
                            onChange={handleChangetext}
                   
                        />
                        <label htmlFor="latitude" className='w-[30%]'><p className='ml-2'><span className='text-red-600'>*</span>Latitude</p></label>

                    </div>
                    <div className="w-full pt-2 flex justify-end">

                        <input
                            className='border w-1/2 text-right bg-slate-300'
                            type="number"
                            id="longitude"
                            name="longitude"
                            onChange={handleChangetext}
                       
                        />
                        <label htmlFor="longitude" className='w-[30%]'><p className='ml-2'><span className='text-red-600'>*</span>Longitude</p></label>

                    </div>
                    <div className="w-full pt-2 flex justify-end">

                        <input
                            className='border w-1/2 text-right bg-slate-300'
                            type="number"
                            id="distanceSignature"
                            name="distanceSignature"
                            onChange={handleChangetext}
                        />
                        <label htmlFor="distanceSignature" className='w-[30%]'><p className='ml-2'><span className='text-red-600'>*</span>מרחק מותר לחתימה</p></label>

                    </div>
                    <div className="w-full pt-2 flex justify-end">

                        <Select
                            className=' w-1/2 border text-right'
                            placeholder="בחר"
                            isClearable
                            isSearchable
                            type="text"
                            id="statusQualificationId"
                            name="statusQualificationId"
                            onChange={(e) => handleChange(e, 'statusQualificationId')}
                            maxMenuHeight={100}
                            options={datafiler?.resStatusQualificationQS?.map((item) => { return { value: item.id, label: item.desc } })}
                        />
                        <label htmlFor="statusQualificationId" className='w-[30%]'><p className='ml-2'><span className='text-red-600'>*</span>סטטוס יום הכשירות</p></label>

                    </div>
                    <div className="w-full pt-2 flex justify-end">

                        <Select
                            className=' w-1/2 border text-right'
                            placeholder="בחר"
                            isClearable
                            isSearchable
                            id="instructorId"
                            name="instructorId"
                            options={datafiler?.resInstructorQS?.map((item) => { return { value: item.id, label: item.desc } })}
                            onChange={(e) => handleChange(e, 'instructorId')}
                            maxMenuHeight={100}
                        />
                        <label htmlFor="instructorId" className='w-[30%]'><p className='ml-2'><span className='text-red-600'>*</span>פרטי המדריך</p></label>

                    </div>
                    <div className="w-full pt-2 flex justify-end">

                        <Select
                            className=' w-1/2 border text-right'
                            placeholder="בחר"
                            isClearable
                            isSearchable
                            id="titleCertificateId"
                            name="titleCertificateId"
                            options={datafiler?.resCertificateQS?.map((item) => { return { value: item.id, label: item.desc } })}
                            onChange={(e) => handleChange(e, 'titleCertificateId')}
                            maxMenuHeight={100}
                        />
                        <label htmlFor="titleCertificateId" className='w-[30%]'><p className='ml-2'><span className='text-red-600'>*</span>כותרת התעודה</p></label>

                    </div>
                    <div className="w-full pt-2 flex justify-end">

                        <input
                            className='border w-1/2 text-right bg-slate-300'
                            type="number"
                            id="days"
                            name="days"
                            onChange={handleChangetext}
                       
                        />
                        <label htmlFor="days" className='w-[30%]'><p className='ml-2'><span className='text-red-600'>*</span>סה"כ ימים</p></label>

                    </div>
                    <div className="w-full pt-2 flex justify-end">

                        <input
                            className='border w-1/2 text-right bg-slate-300'
                            type="number"
                            id="hours"
                            name="hours"
                            onChange={handleChangetext}
                       
                        />
                        <label htmlFor="hours" className='w-[30%]'><p className='ml-2'><span className='text-red-600'>*</span>סה"כ שעות</p></label>

                    </div>
                    <div className="w-full pt-2 flex justify-end">

                        <input
                            className=''
                            type="checkbox"
                            id="manualSignatureWindow"
                            name="manualSignatureWindow"
                            // onChange={handleChangetext}
                       
                        />
                        <label htmlFor="manualSignatureWindow" className='w-[30%]'><p className='ml-2'><span className='text-red-600'>*</span>השאר ריק (לשימוש עבור חריגות במהלך יום הכשירות)</p></label>

                    </div>
                    <div className="w-full pt-2 flex justify-end">

<input
    className='border w-1/2 h-fit '
    type="file"
    accept='image/*'
    id="urlimage"
    name="urlimage"
    // value={formData.urlimage}
    onChange={handleFileChange}
/>
<label htmlFor="urlimage" className='w-[30%]'><p className='ml-2'><span className='text-red-600'>*</span>חתימה (העלאת הקובץ)</p></label>

</div>

                </form>
                <div className='w-full p-2   flex justify-end'>
                    <button className='border bg-green-500 hover:bg-green-300 p-1 px-3' onClick={() => {

                        row ? handleEditClass(row.id) : handleSubmit()

                        // handleSubmit()


                    }}>שמור</button>
                    <button className='border bg-slate-700 hover:bg-slate-500 p-1 px-3' onClick={() => {
                        // reset()
                        // setShowModal(false)
                        handleColse()
                    }}>בטל</button>
                </div>
            </div>

        </Modall>)
}

export default ModalNewTrainingDays