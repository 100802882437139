import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import axios from 'axios'
import ModalMyTrainingDays from './ModalMyTrainingDays'
import { useParams } from 'react-router-dom'
const GET_URL = 'dashboard/member/my_qualification_day'

const MyTrainingDays = () => {
    const { corseId } = useParams();


   
    const [fetchData, setFetchData] = useState([])
    const [loading, setloading] = useState(true)
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1)
    const [modalIsOpen, setModalIsOpen] = useState(false);

 
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2); // Ensure 2 digits for month
        const day = ('0' + date.getDate()).slice(-2); // Ensure 2 digits for day
        return `${day}/${month}/${year}`;
    };
    
    
    
    const getTrainingDays = async (page) => {
        setloading(true)
        await axios.get(GET_URL, {
            params: {
                
                "page": page
                , "size": perPage,


            }
        })
            .then(response => {
                if (response.status == 200) {
                    setFetchData(response?.data)
                    // setdataForfilterScrach(response?.data)
                    // setTotalRows("");
                }
            })
        setloading(false)

    }
    useEffect(() => {
        getTrainingDays(currentPage - 1)
        if(corseId)
            {
                setModalIsOpen(true) 
            }
    }, [currentPage, perPage])

    const handlePerRowsChange = async (newPerPage) => {
        setPerPage(newPerPage);

    }
    const handlePageChange = (page) => {
        // fetchUsers(page);
        // getData(page);
        setCurrentPage(page);
    };

    const columns = [
        // {
        //     name: "פעולות",

        //     cell: row => <div className='w-full'>
        //         <a title="ייצא תעודה"  className='p-0'
        //                 href={`https://archivebureau-tests.s3.eu-west-3.amazonaws.com/${row.imgCertificate}`}
        //      ><button className=' border border-gray-500 p-1 opacity-25 hover:opacity-100' ><MdAssignmentReturned /></button></a>
        //         <button title="מחיקה" className=' border border-gray-500 ml-1 p-1 opacity-25 hover:opacity-100' onClick={() => {
        //             // setId(row)
        //             // setModalIsOpen("Delete")
        //         }}><MdAssignment /></button>
        //         <button title="מחיקה" className=' border border-gray-500 ml-1 p-1 opacity-25 hover:opacity-100' onClick={() => {
        //             setId(row)
        //             setModalIsOpen("Delete")
        //         }}><IoTrashOutline /></button>
        //         <button className=' border border-gray-500 p-1  ml-1 opacity-25 hover:opacity-100' onClick={() => {
        //         // setnamecalss(row?.description)
        //         // setAction("edit")
        //         setId(row)
        //         setModalIsOpen("Edit")
        //     }
        //     }><BsPencil /></button>
        //     </div>,


        //     style: {
        //         fontSize: '10px',
        //         justifyContent: 'center',



        //         // padding: '2px'
        //         // width: '100%'
        //     },
        //     grow: 1,


        // },
        {
            name: "תעודה",
            grow: 2,
            selector: (row) => row?.reportCertificateNumberDto?.number,
            style: {
                fontSize: '9px',
                justifyContent: 'center',
                width: '100px'
            },
            sortable: true,
        },

        {
            name: "סטטוס הכשירות",
            // grow: 3,
            selector: (row) => row?.mySelfQualificationDto?.statusQualification?.statusDescription,
            style: {
                fontSize: '10px',
                justifyContent: 'center',
                // width: '100px'
            },
            sortable: true,
        },
        // {
        //     name: "תאריך הרשמה",
        //     grow: 1,
        //     selector: (row) => formatDate(row?.dateRegister),
        //     style: {
        //         fontSize: '10px',
        //         justifyContent: 'center',
        //         // width: '500px'
        //     },
        //     sortable: true,
        // },
        {
            name: "שם המדריך",
            // grow: 3,
            selector: (row) => row?.mySelfQualificationDto?.instructor?.name,
            style: {
                fontSize: '9px',
                justifyContent: 'center',
                // width: '100px'
            },
            sortable: true,
        },
        {
            name: "תאריך",
            // grow: 3,
            selector: (row) => row?.mySelfQualificationDto?.startDate? formatDate(row?.mySelfQualificationDto?.startDate):"",
            style: {
                fontSize: '9px',
                justifyContent: 'center',
                // width: '100px'
            },
            sortable: true,
        },
        {
            name: "שם יום הכשירות",
            // grow: 3,
            selector: (row) => row?.mySelfQualificationDto?.name,
            style: {
                fontSize: '9px',
                justifyContent: 'center',
                // width: '100px'
            },
            sortable: true,
        },


        {
            name: "מספר יום הכשירות",
            // grow: 3,
            selector: (row) => row?.mySelfQualificationDto?.number,
            style: {
                fontSize: '9px',
                justifyContent: 'center',
                // width: '100px'
            },
            sortable: true,


        },
        {
            name: "אשכול",
            // grow: 3,
            selector: (row) => row?.mySelfQualificationDto?.cluster?.name,
            style: {
                fontSize: '9px',
                justifyContent: 'center',
                // width: '100px'
            },
            sortable: true,


        },
        {
            name: "מספר אשכול",
            // grow: 3,
            selector: (row) => row?.mySelfQualificationDto?.cluster?.number,
            style: {
                fontSize: '9px',
                justifyContent: 'center',
                // width: '100px'
            },
            sortable: true,


        },



    ];



    const customStyles = {
        header: {
            style: {

                justifyContent: 'center',

            },
        },
        id: {
            style: {
                '&:2': { backgroundColor: '#EEEEEE' },
            }
        },
        headRow: {
            style: {
                justifyContent: 'center',
                borderTopStyle: 'solid',
                borderTopWidth: '1px',
                // borderTopColor: defaultThemes.default.divider.default,
            },
        },
        headCells: {
            style: {

                // whiteSpace: 'normal',
                // overflow: 'visible',
                // textOverflow: 'clip',
                // maxWidth: 'none', // Allow headers to expand as needed
                // padding: '10px'
                // ,
                fontSize: '10px',
                justifyContent: 'center',
                borderRightStyle: 'solid',
                borderRightWidth: '1px',
                paddingLeft: '0px',
                paddingRight: '0px'
                // borderRightColor: defaultThemes.default.divider.default,

            },
        },
        cells: {
            style: {
                '&:not(:last-of-type)': {
                    // padding: '10px'
                    // ,
                    //       paddingLeft:'0px',
                    // paddingRight:'0px',
                    //     borderRightStyle: 'solid',
                    //     borderRightWidth: '1px',
                    // borderRightColor: defaultThemes.default.divider.default,
                },
                borderRightStyle: 'solid',
                borderRightWidth: '1px',
                paddingLeft: '0px',
                paddingRight: '0px',
            },
        },
        rows: {
            style: {
                backgroundColor: '#FFFFFF',
                '&:active': {
                    backgroundColor: '#EEEEEE',
                },
            },
        },
    }








  return (
<div className='bg-white p-2'>
<div className='bg-[#0d406b] text-right text-white font-semibold text-[20px] p-2 font-sans grid grid-cols-2 rounded-t-lg border '>
<div className='flex justify-end'>
<button className=' mr-3'>
    <p>ייצאv</p>
</button>
<button className='bg-[#1e8e2b] border border-[#1a7925] text-white w-fit h-fit flex justify-self-end self-center p-2 px-3 rounded-lg'
 onClick={()=>{setModalIsOpen(true)}}>
  חדש+
</button>

</div>
<p>ימי הכשרות שלי</p>


</div>
{(modalIsOpen)&&<ModalMyTrainingDays corseId={corseId} setModalIsOpen={setModalIsOpen}></ModalMyTrainingDays>}
<DataTable columns={columns} data={fetchData} pagination customStyles={customStyles}
                paginationServer
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                progressPending={loading} className='border' >
            </DataTable>


</div>
  )
}

export default MyTrainingDays