import React from 'react'
import { Outlet } from 'react-router-dom'
import MainNavigation from '../components/MainNavigation'
import Header from '../components/Header'
import Footer from '../components/Footer'
const Main = () => {
    return (
        <>
            <Header/>
            <MainNavigation/>

            <Outlet/>
            <Footer/>
        </>
    )
}

export default Main