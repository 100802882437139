import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { BsPencil } from "react-icons/bs";
import { IoTrashOutline } from "react-icons/io5";
import axios from 'axios'
import ButtonNew from '../../ButtonNew';
import ModalEdit from '../../ModalEdit';
import ModalNewPaying from '../../adminstration/ModalNewPaying';
import ModalNewTitle_certificate from './ModalNewTitle_certificate';
const GET_URL = 'admin/qualification/title_certificate'

const Title_certificate = ({setHide}) => {
    const [modalIsOpen, setModalIsOpen] = useState("");
    const [id, setId] = useState("");
    const [fetchData, setFetchData] = useState([])
    const [loading, setloading] = useState(false)
    // const [totalRows, setTotalRows] = useState(0);
    // const [perPage, setPerPage] = useState(10);
    // const [currentPage, setCurrentPage] = useState(1);

    const handleDetelePaying_customer = async () => {

        await axios.delete(`${GET_URL}/${id.id}`).then(response => {
            if (response.status == 200) {
                handleColse()
                getTitle_certificate()
                console.log("ok")
            }
            else {
                console.log("error")
            }
        })
    }
    const getTitle_certificate = async () => {
        // setloading(true);
            await axios.get(GET_URL,{
                })
        .then(response => {
            setFetchData(response?.data)
        })
        setloading(false)
    }

    useEffect(() => {
        getTitle_certificate()
    }, [])

    const handleColse = () => {
        setModalIsOpen("")
    }
    useEffect(() => {
        if (modalIsOpen) {
            document.body.style.overflow = 'hidden'; // Disable body scrolling
          } else {
            document.body.style.overflow = 'auto'; // Enable body scrolling
          }
      
          return () => {
            document.body.style.overflow = 'auto'; // Cleanup: ensure body scroll is reset
          };
        // setHide(modalIsOpen)
    }, [modalIsOpen])
    const columns = [
        {
            name: "פעולות",
    
            cell: row => <div>
                {/* <button className=' border border-gray-500 p-2 opacity-15 hover:opacity-100' onClick={() => {
                // setnamecalss(row?.description)
                // setAction("edit")
                setId(row)
                setModalIsOpen("Edit")
            }
            }><BsPencil /></button> */}
                <button title="מחיקה" className=' border border-gray-500 ml-2 p-2 opacity-15 hover:opacity-100' onClick={() => {
                    setId(row)
                    setModalIsOpen("Delete")
                }}><IoTrashOutline /></button> </div>,
            style: {
                fontSize: '10px',
                justifyContent: 'center',
    
            },
            grow: 0.5,
    
    
        },
      {
        name: "לוגו",
        grow: 1,
        selector: (row) => row?.logo?<img src={`https://archivebureau-tests.s3.eu-west-3.amazonaws.com/${row?.logo}`} className='w-[80px]'/>:"",
        style: {
            fontSize: '16px',
            justifyContent: 'center',
            width: '100px'
        },
        sortable: true,
    },
    {
      name: "כיתוב הכותרת",
      grow: 3,
      selector: (row) => row?.caption,
      style: {
          fontSize: '16px',
          justifyContent: 'center',
          width: '100px'
      },
      
    },
        {
          name: "שם הכותרת",
          grow: 3,
          selector: (row) => row?.title,
          style: {
              fontSize: '16px',
              justifyContent: 'center',
              width: '100px'
          },
          sortable: true,
      },

    ];
    
    
    
    const customStyles = {
        header: {
            style: {
    
                justifyContent: 'center',
    
            },
        },
        id: {
            style: {
                '&:2': { backgroundColor: '#EEEEEE' },
            }
        },
        headRow: {
            style: {
                justifyContent: 'center',
                borderTopStyle: 'solid',
                borderTopWidth: '1px',
                // borderTopColor: defaultThemes.default.divider.default,
            },
        },
        headCells: {
            style: {
    
                justifyContent: 'center',
                borderRightStyle: 'solid',
                borderRightWidth: '1px',
                // borderRightColor: defaultThemes.default.divider.default,
    
            },
        },
        cells: {
            style: {
                '&:not(:last-of-type)': {
    
                    borderRightStyle: 'solid',
                    borderRightWidth: '1px',
                    // borderRightColor: defaultThemes.default.divider.default,
                },
            },
        },
        rows: {
            style: {
                backgroundColor: '#FFFFFF',
                '&:active': {
                    backgroundColor: '#EEEEEE',
                },
            },
        },
    }
      return (
        <div className='w-full p-3'>
            <ButtonNew active={"כותרת לתעודה"} setModalIsOpen={setModalIsOpen} />
          <DataTable columns={columns} data={fetchData} pagination 
              customStyles={customStyles} 
                // paginationServer
                // paginationTotalRows={totalRows}
                // onChangeRowsPerPage={handlePerRowsChange}
                // onChangePage={handlePageChange}
              progressPending={loading} className='border' >
              
              </DataTable>
              {modalIsOpen == "New" && <ModalNewTitle_certificate showModal={modalIsOpen == "New"} handleColse={handleColse} getDepartment={getTitle_certificate}>
            </ModalNewTitle_certificate>}
            {modalIsOpen == "Edit" && <ModalNewTitle_certificate showModal={modalIsOpen == "Edit"} handleColse={handleColse} getDepartment={getTitle_certificate} row={id}>
            </ModalNewTitle_certificate>}
            {modalIsOpen == "Delete" && <ModalEdit showModal={modalIsOpen == "Delete"} handleColse={handleColse} handleDetele={handleDetelePaying_customer}></ModalEdit>}
          </div>
      )
}

export default Title_certificate