import React, { useRef, useState } from 'react'
import SignatureCanvas from 'react-signature-canvas'
import v from '../assets/v.svg'
import x from '../assets/x.svg'
import Header from '../components/Header'

const Signup = () => {

    const arr=["בחר","עצמאי","שכיר במוסד ממשלתי","שכיר בצה\"ל","שכיר בחברה לייעוץ בטיחות","שכיר בקיבוץ/מקום עבודה שיתופי","שכיר במקום עבודה פרטי","אחר"]

    const arrtel=["בחר","050","051","052","053","054","055","057","058","059"]
    
    const sign= useRef()
    const [validID,setValidID]=useState(0)
    const [validTal,setValidTal]=useState(false)

    function clearSign(){
        sign.current.clear()
            // console.log(sign)
    }
    function checklen(number){
        let strNumber = String(number).trim();
        if (strNumber.length != 7) {
            setValidTal(true)
            return
        }
        setValidTal(false)
    }

    function isIsraeliIdValid(id)
    {
        let strId = String(id).trim();
        if (strId.length == 0) {
            setValidID(0)
            return
        }
        if (strId.length > 9) {
            // return false;
            setValidID(2);
        }
        if (strId.length < 9) {
            while (strId.length < 9) strId = "0" + strId;
        }
        let counter = 0, rawVal, actualVal;
        for (let i = 0; i < strId.length; i++) {
            rawVal = Number(strId[i]) * ((i % 2) + 1);
            actualVal = rawVal > 9 ? (rawVal - 9) : rawVal;
            counter += actualVal;
        }
        // return (counter % 10 === 0);
        setValidID(counter % 10 === 0?1:2);
    };


    return (
        <div className='bg-[#f3f3f3] p-4 pb-6 min-h-screen'>
            <Header/>
            <form className='flex flex-col items-center border rounded-md bg-white ' style={{direction: "rtl"}}>
                <text className='font-black underline text-5xl'> טופס הרשמה ללשכת ממוני הבטיחות בישראל (ע.ר.)</text>
                {/* <fieldset class="border border-solid border-gray-300 p-3  flex flex-col items-end "> */}
                <fieldset class="border border-solid border-gray-300 p-3  bg-[#caf5ff] flex flex-col w-[40%] rounded-md my-2 ">
                
                    <legend  align="right" className='bg-black text-white p-3 rounded-md'>פרטים אישיים</legend>
                    <label htmlFor='fristname' className='text-right'>
                    שם פרטי*
                    </label>

                    <input className='h-7' required/>
                    
                    <label  htmlFor='lastname'className='text-right'>שם משפחה*</label>
                    <input className='h-7' />

                    <label htmlFor='Id' className='text-right'>ת.ז*</label>
                    <div className='flex'>
                    <input type='' className='h-7' onBlur={(event)=>{isIsraeliIdValid(event.target.value)}}/>
                    {validID==1&&<img src={v} alt="v.svg" />}
                    {validID==2&&<img src={x} alt="x.svg" />}

                    </div>
                    <label htmlFor='Id' className='text-right'>תאריך לידה*</label>
                    <input className='h-7' type='date'/>
                    <label htmlFor='Id' className='text-right'>מקום עבודה*</label>
                    <select className='h-7 mt-2'>
                    {
                        arr.map((index)=>{
                                return(<option className='w-full'>
                                {index}
                            </option>)
                        })
                        }
                    </select>
                    
                </fieldset>


                <fieldset class="border border-solid border-gray-300 p-3 bg-[#caf5ff] flex flex-col w-[40%] rounded-md my-2">
                
                <legend  align="right" className='bg-black text-white p-3 rounded-md'>כתובת</legend>
                <label htmlFor='Id' className='text-right'>רחוב</label>
                <input className='h-7'/>
                <label htmlFor='Id' className='text-right'>מספר בית</label>
                <input className='h-7'/>
                <label htmlFor='Id' className='text-right'>מספר דירה</label>
                <input className='h-7'/>
                <label htmlFor='Id' className='text-right'>עיר</label>
                <input className='h-7'/>
                
                </fieldset>

                <fieldset class="border border-solid border-gray-300 p-3 bg-[#caf5ff] flex flex-col w-[40%] rounded-md my-2 text-right"  >
                
                <legend  align="right" className='bg-black text-white p-3 rounded-md'>פרטי תקשורת</legend>
                
                <label>דוא"ל*</label>
                <input type="email" className='h-7'/>
                <label className=''>טלפון ראשי*</label>
                <div className=' grid grid-cols-4 gap-4'>
               
                    <div className=' grid col-span-2'>
                    <input type=''  className='col-span-2 h-7' onBlur={(event)=>{checklen(event.target.value)}}/>
                    {validTal&&<img src={x} alt="x.svg" />}
                    </div>
                    <select className='h-7 ml-15'>
                    {
                        arrtel.map((index)=>{
                                return(<option className='w-full'>
                                {index}
                            </option>)
                        })
                        }
                    </select>
                    
                </div>
                <label className=''>טלפון נוסף (אופציונלי)</label>
                <div className=' grid grid-cols-4 gap-4'>
               
                    <div className=' grid col-span-2'>
                    <input type=''  className='col-span-2 h-7'/>
                    {/* {false&&<img src={x} alt="x.svg" />} */}
                    </div>
                    <select className='h-7 ml-15'>
                    {
                        arrtel.map((index)=>{
                                return(<option className='w-full'>
                                {index}
                            </option>)
                        })
                        }
                    </select>
                    
                </div>
                </fieldset>

                
                
                <fieldset class="border border-solid border-gray-300 p-3 bg-[#caf5ff] flex flex-col w-[40%] rounded-md my-2" >
                
                <legend  align="right" className='bg-black text-white p-3 rounded-md'>אישור כשירות</legend>
                <label className='text-right'>מספר נ.ש.*
                </label>
                
                <input type='' className='border border-solid border-spacing-1 w-[80%] h-7'/>
                <a  href="https://serviceproviders.labor.gov.il/Pages/Person.aspx" target="_blank" className='text-right w-fit bg-slate-500 m-1 p-2 rounded-lg text-white'> מעבר לאתר משרד העבודה</a>
                </fieldset>
                
                
                
                
                <fieldset class="border border-solid border-gray-300 p-3 bg-[#caf5ff] flex flex-col w-[40%] rounded-md my-2" >
                
                <legend  align="right" className='bg-black text-white p-3 rounded-md'>  קבצים מצורפים</legend>
                <label className='text-right'>תמונת פנים (התמונה תשמש לכרטיס חבר)</label>
                <input type='file' className='border border-solid border-spacing-1 w-[50%]'/>
                <label className='text-right'>אישור כשירות
                </label>
                
                <input type='file' className='border border-solid border-spacing-1 w-[50%]'/>
                </fieldset>

            
                
              
                
                <fieldset class="border border-solid border-gray-300 p-3 bg-[#caf5ff] flex flex-col w-[40%] rounded-md my-2 text-right" >
                
                <legend  align="right" className='bg-black text-white p-3 rounded-md'>הצהרה</legend>
                <p className=' whitespace-pre-line'> אני הח"מ, מבקש להיות חבר בעמותה – לשכת ממוני הבטיחות בישראל מקצונים זה אנחנו (ע.ר.) 580700185.
                מטרות העמותה כפי שרשומות להלן ותקנונה ידועים לי.
אם אתקבל כחבר אני מתחייב לקיים את הוראות התקנון ואת החלטות האסיפה הכללית של העמותה.
הנני מאשר / ת שליחת הודעות דוא"ל מעת לעת הכוללות אישורים, הנחיות והודעות אחרות לכתובת הדוא"ל / למספר הסלולרי שסיפקתי.
מטרות העמותה
1. הקמת ארגון יציג לפי כל דין לכלל ממוני הבטיחות בישראל.
2. לפעול למען קידומם המקצועי של ממוני הבטיחות בין היתר לפי המלצת "וועדת אדם".
3. לפעול למען רווחתם של ממוני הבטיחות בין היתר על ידי הפחתת כמות ימי העיון הנדרשת ממונה הבטיחות לפי דין ו/או הגבלת התשלום בגין ביצוע ימי העיון באופן שלא יעלה על 100 ₪ לכל יום עיון.
תקנון העמותה
התקנון המצוי באתר העמותה.
</p>
                <label>חתימה:</label>
                <SignatureCanvas backgroundColor='white' canvasProps={{ className: 'w-[200px] h-[200px] m-2'}}  ref={sign}></SignatureCanvas>
                <button type='button' className='p-4 w-fit' onClick={clearSign}>נקנה חתימה</button>
                </fieldset>

                <button className='p-4 bg-lime-500 mb-4 mt-2' >שלחת טופס</button>
               
            </form>

        </div>
    )
}

export default Signup