export function formatOnlyDate(dateString) {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0]; // Extracts only the 'YYYY-MM-DD' part
  }


export function formatOnlyTime(dateString) {
    const date = new Date(dateString);
    const options = { 
      timeZone: 'Asia/Jerusalem', 
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',  // This is optional if you don't want seconds
      hour12: false   
    };
    
    const israelTime = date.toLocaleString('en-IL', options);
    return(israelTime)
 
  }


  export function formatDateIL(dateString)  {
    if (dateString =="") return ""
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Ensure 2 digits for month
    const day = ('0' + date.getDate()).slice(-2); // Ensure 2 digits for day
    console.log(`${day}/${month}/${year}`)
    return `${day}/${month}/${year}`;
}; 

export function convertDateFormat(dateStr) {
  // Split the date string by the dash separator
  const [year, month, day] = dateStr.split('-');

  // Return the date in the format "dd-MM-YYYY"
  console.log(`${day}-${month}-${year}`)
  return `${day}-${month}-${year}`;
}


export const convertToIsraelTime = (dateString) => {
  const date = new Date(dateString);

  // Convert to Israel time (Asia/Jerusalem timezone)
  const options = { 
    timeZone: 'Asia/Jerusalem', 
    year: 'numeric', 
    month: '2-digit', 
    day: '2-digit', 
    
  };
  
  const israelTime = date.toLocaleString('en-IL', options);
  return israelTime;
};