import React, { useState, useEffect } from 'react'
import axios from 'axios'
import ReDataTable from '../../../../ui/ReDataTable';
import ButtonNew from '../../../../ButtonNew';
import ModalEdit from '../../../../ModalEdit';
import RegistersMemberQualificationFilter from './RegistersMemberQualificationFilter';
import { MdAssignmentReturned } from "react-icons/md"
import { IoTrashOutline } from 'react-icons/io5';

const RegistersMemberQualification = ({row}) => {
    // const GET_URL=`/admin/qualification/get_register_member_qualification/${row?.id}`
    const GET_URL=`admin/qualification/get_register_member_qualification/${row?.id}?sortBy=name`
    const [dataFillter, setDataFillter] = useState({
      "member_number": ""
   })
   const [modalIsOpen, setModalIsOpen] = useState("");
   const [id, setId] = useState("");
 const handleColse = () => {
        setModalIsOpen("")
    }

    // const handleDetelePaying_customer = async () => {

    //     await axios.delete(`${GET_URL}/${id.id}`).then(response => {
    //         if (response.status == 200) {
    //             handleColse()
    //             getPaying_customers(currentPage - 1)
    //             console.log("ok")
    //         }
    //         else {
    //             console.log("error")
    //         }
    //     })
    // }
    const getData = async (page,perPage) => {
        const response= await axios.get(GET_URL, {
            params: {
              ...dataFillter,
                
                "page": page
                , "size": perPage,
            }
        })
            if (response.status=="200") 
             {
                const response1 = (response?.data?.resMemberRegisters)
                const response2= (response?.data?.totalElements);
                return [response1, response2];
            }
            const response1 = []
            const response2= []
            return [response1, response2];
      };
    const columns = [
        {
            name: "פעולות",
            grow: 2,
            selector: (row) => <div className='w-full'>
           
            <button title="מחיקה" className=' border border-gray-500 ml-1 p-1 opacity-25 hover:opacity-100' onClick={() => {
                // setId(row)
                // setModalIsOpen("Delete")
            }}></button>
            <button title="מחיקה" className=' border border-gray-500 ml-1 p-1 opacity-25 hover:opacity-100' onClick={() => {
                setId(row)
                setModalIsOpen("Delete")
            }}><IoTrashOutline /></button></div>,
            style: {
              fontSize: '16px',
              justifyContent: 'center',
              width: '100px'
            },
            sortable: true,
          },
          {
            name: "מספר תעודה",
            grow: 2,
            selector: (row) => row?.certificateNumber,
            style: {
              fontSize: '16px',
              justifyContent: 'center',
              width: '100px'
            },
            sortable: true,
          },
          {
            name: "תעודה",
            grow: 2,
            selector: (row) => 
              <a title="ייצא תעודה"  className='p-0'
                        href={`https://archivebureau-tests.s3.eu-west-3.amazonaws.com/${row.urlCertificate}`}
             ><button className=' border border-gray-500 p-1 opacity-25 hover:opacity-100' ><MdAssignmentReturned /></button></a>
              ,
            style: {
              fontSize: '16px',
              justifyContent: 'center',
              width: '100px'
            },
            
          },

       
       
        {
          name: "מספר חתימות",
          grow: 2,
          selector: (row) => row?.numberSignatures?.length,
          style: {
            fontSize: '16px',
            justifyContent: 'center',
            width: '100px'
          },
          sortable: true,
        },
        {
            name: "טלפון ראשי",
            grow: 2,
            selector: (row) => row?.mainPhone,
            style: {
              fontSize: '16px',
              justifyContent: 'center',
              width: '100px'
            },
            sortable: true,
          },
        {
            name: "דוא\"ל",
            grow: 2,
            selector: (row) => row?.email,
            style: {
              fontSize: '16px',
              justifyContent: 'center',
              width: '100px'
            },
            sortable: true,
          },
          {
            name: "סטטוס חבר",
            grow: 2,
            selector: (row) => row?.status?.statusDescription,
            style: {
              fontSize: '16px',
              justifyContent: 'center',
              width: '100px'
            },
            sortable: true,
          },
          {
            name: "שם משפחה",
            grow: 2,
            selector: (row) => row?.lastName,
            style: {
              fontSize: '16px',
              justifyContent: 'center',
              width: '100px'
            },
            sortable: true,
          },
          {
            name: "שם פרטי",
            grow: 2,
            selector: (row) => row?.firstName,
            style: {
              fontSize: '16px',
              justifyContent: 'center',
              width: '100px'
            },
            sortable: true,
          },
          {
            name: "ת.ז.",
            grow: 2,
            selector: (row) => row?.idNumber,
            style: {
              fontSize: '16px',
              justifyContent: 'center',
              width: '100px'
            },
            sortable: true,
          },
          {
            name: "מספר חבר",
            grow: 2,
            selector: (row) => row?.number,
            style: {
              fontSize: '16px',
              justifyContent: 'center',
              width: '100px'
            },
            sortable: true,
          },
       
    
    
    
      ];

    return (
        <div style={{direction:"ltr"}}>
          <RegistersMemberQualificationFilter setDataFillterout={setDataFillter} row={row} />
            <ButtonNew active={"ממונים רשומים ליום הכשירות"}/>
             <ReDataTable  columns={columns} getData={getData}/>
             {modalIsOpen == "Delete" && <ModalEdit showModal={modalIsOpen == "Delete"} handleColse={handleColse} 
            //  handleDetele={handleDetelePaying_customer}
             ></ModalEdit>}
        </div>
    )
}

export default RegistersMemberQualification