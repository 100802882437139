import React from 'react'
import Modall from './Modall'

const ModalEdit = ({showModal,handleColse,handleDetele}) => {
  return (
    <Modall showModal={showModal}>
        <div className=' w-full divide-y-2  divide-slate-500'>
        <div className='w-full bg-[#0d406b] flex justify-end'>
          <button  className='p-1 px-2 m-3 border bg-slate-500 hover:bg-slate-200' onClick={handleColse}
            // setShowModal(false)

             >x</button>
        </div>
        <div className='w-full p-5 flex flex-row justify-end '>
          <p className='w-full text-center'>?האם אתה בטוח שברצונך למחוק את הרשומה</p>

        </div>

        <div className='w-full p-2   flex justify-center'>
          <button className='border bg-blue-500 hover:bg-blue-950 p-1 px-3 rounded-lg' onClick={handleDetele}>אוקי</button>
          <button className='border bg-slate-700 hover:bg-slate-500 p-1 px-3'  onClick={handleColse}
            >בטל</button>
        </div>
      </div>

    </Modall>
  )
}

export default ModalEdit