import React from 'react'

const SubTab = ({children, active,onClick}) => {
  return (
    <li onClick={onClick} className={active?'bg-[#D8D8D8] px-2 py-3 mr-[2.4px] text-xs text-[#333333] rounded-t-lg':'hover:bg-[#D8D8D8] px-2 py-3 mr-[2.4px] text-xs text-[#333333] rounded-t-lg'}>

        {children}
    </li>
  )
}

export default SubTab