import React, { useEffect, useState } from 'react'
import Fillter from '../Fillter'
import Select from "react-select"
import axios from 'axios'
const GET_Filter = 'admin/setting/scroll_filter_filed_rig_history'

const ReportTrainingDaysFilter = ({setDataFillterout}) => {
    
    const [paying_label, setPaying_label] = useState("")
    const [dataFillter, setDataFillter] = useState({
        "firstName": "",
        "lastName": "",
        "memberIdNumber": "",
        "memberNumber": "",
        "trainingName": "",
        "trainingNumber": "",
        "trainingDate": "",
        "trainingStatusName": "",
        "trainingClusterName": "",
        "clusterNumber": "",
        "paying_id": "",
    })

    const [datafiler, setDatafiler] = useState("");

    const getFilter = async () => {

        await axios.get(GET_Filter, {
        })
            .then(response => {
                setDatafiler(response?.data)

            })

    }
    useEffect(() => {
        getFilter()
    }, [setDataFillter])



    const restart = async () => {
        setDataFillter(
            {
                "member_name": "",
                "member_lastName": "",
                "member_identity": "",
                "member_number": "",
                "qul_name": "",
                "qul_number": "",
                "dateSt": "",
                "dateEn": "",
                "statusDescription": "",
                "trainingClusterName": "",
                "cluster_name": "",
                "cluster_number": "",
                "paying_id": "",

            }
        )
        setDataFillterout({
            "member_name": "",
            "member_lastName": "",
            "member_identity": "",
            "member_number": "",
            "qul_name": "",
            "qul_number": "",
            "dateSt": "",
            "dateEn": "",
            "statusDescription": "",
            "trainingClusterName": "",
             "cluster_name": "",
             "cluster_number": "",
            "paying_id": "",
        })
        // await getPaying_customersFilter()
    }


    const handleChange = (e, name) => {
        setDataFillter({
            ...dataFillter,
            [name]: e?.value
        });
    }
    const handleChange2 = (e,name) => {
     
        setDataFillter({
            ...dataFillter,
            [name]: e?.value,
           
        })
        setPaying_label( e?.label)
  
    }
    const actionFilter = () => {
        setDataFillterout(dataFillter)
    }

    return (
        <Fillter actionFilter={actionFilter} restart={restart}>
            <div className='grid grid-cols-2 '>
                <div className='grid col-span-1 gap-y-2'>
                    <div className='flex justify-end items-center'>

                        <Select className=' w-2/3 text-right'
                            type="text"
                            id="member_lastName"
                            name="member_lastName"
                            value={dataFillter?.member_lastName ? { value: dataFillter.member_lastName, label: dataFillter.member_lastName } : null}
                            onChange={(e) => handleChange(e, 'member_lastName')}
                            options={datafiler?.member_lastNames?.map((item) => { return { value: item, label: item } })}
                            placeholder="...חפש"
                            isClearable
                            isSearchable
                            maxMenuHeight={100} />
                        <label className='ml-3 w-1/6' htmlFor="member_lastName">שם משפחה</label>
                    </div>
                    <div className='flex justify-end items-center'>


                        <Select className=' w-2/3 text-right'
                            type="text"
                            id="member_number"
                            name="member_number"
                            value={dataFillter?.member_number ? { value: dataFillter.member_number, label: dataFillter.member_number } : null}
                            onChange={(e) => handleChange(e, 'member_number')}
                            options={datafiler?.member_numbers?.map((item) => { return { value: item, label: item } })}
                            placeholder="...חפש"
                            isClearable
                            isSearchable
                            maxMenuHeight={100} />
                        <label className='ml-3 w-1/6' htmlFor="member_number">מספר חבר</label>
                    </div>
                    <div className='flex justify-end items-center'>


                        <Select className=' w-2/3 text-right'
                            type="text"
                            id="qul_number"
                            name="qul_number"
                            value={dataFillter?.qul_number ? { value: dataFillter.qul_number, label: dataFillter.qul_number } : null}
                            onChange={(e) => handleChange(e, 'qul_number')}
                            options={datafiler?.qul_numbers?.map((item) => { return { value: item, label: item } })}
                            placeholder="...חפש"
                            isClearable
                            isSearchable
                            maxMenuHeight={100} />

                        <label className='ml-3 w-1/6' htmlFor="qul_number">מספר הדרכה</label>
                    </div>
                    <div className='flex justify-end items-center gap-y-2'>


                        <Select className=' w-2/3 text-right'
                            type="text"
                            id="cluster_number"
                            name="cluster_number"
                            value={dataFillter?.cluster_number ? { value: dataFillter.cluster_number, label: dataFillter.cluster_number } : null}
                            onChange={(e) => handleChange(e, 'cluster_number')}
                            options={datafiler?.cluster_numbers?.map((item) => { return { value: item, label: item } })}
                            placeholder="...חפש"
                            isClearable
                            isSearchable
                            maxMenuHeight={100} />

                        <label className='ml-3 w-1/6' htmlFor="cluster_number">קוד נושא</label>
                    </div>
                    <div className='flex justify-end items-center'>

                        <Select className=' w-2/3 text-right'
                            type="text"
                            id="statusDescription"
                            name="statusDescription"
                            value={dataFillter?.statusDescription ? { value: dataFillter.statusDescription, label: dataFillter.statusDescription } : null}
                            onChange={(e) => handleChange(e, 'statusDescription')}
                            options={datafiler?.qul_statusDescription?.map((item) => { return { value: item, label: item } })}
                            placeholder="...חפש"
                            isClearable
                            isSearchable
                            maxMenuHeight={100} />



                        <label className='ml-3 w-1/6' htmlFor="statusDescription">סטטוס</label>
                    </div>
                </div>
                <div className='grid col-span-1 '>
                    <div className='flex justify-end items-center'>

                        <Select className=' w-2/3 text-right'
                            type="text"
                            id="member_name"
                            name="member_name"
                            value={dataFillter?.member_name ? { value: dataFillter.member_name, label: dataFillter.member_name } : null}
                            onChange={(e) => handleChange(e, 'member_name')}
                            options={datafiler?.member_firstNames?.map((item) => { return { value: item, label: item } })}
                            placeholder="...חפש"
                            isClearable
                            isSearchable
                            maxMenuHeight={100} />
                        <label className='ml-3 w-1/6' htmlFor="member_name">שם פרטי</label>
                    </div>
                    <div className='flex justify-end items-center'>

                        <Select className=' w-2/3 text-right'
                            type="text"
                            id="member_identity"
                            name="member_identity"
                            value={dataFillter?.member_identity ? { value: dataFillter.member_identity, label: dataFillter.member_identity } : null}
                            onChange={(e) => handleChange(e, 'member_identity')}
                            options={datafiler?.member_Ids?.map((item) => { return { value: item, label: item } })}
                            placeholder="...חפש"
                            isClearable
                            isSearchable
                            maxMenuHeight={100} />

                        <label className='ml-3 w-1/6' htmlFor="member_identity">ת.ז.</label>
                    </div>
                    <div className='flex justify-end items-center'>
                        <Select className=' w-2/3 text-right'
                            type="text"
                            id="qul_name"
                            name="qul_name"
                            value={dataFillter?.qul_name ? { value: dataFillter.qul_name, label: dataFillter.qul_name } : null}
                            onChange={(e) => handleChange(e, 'qul_name')}
                            options={datafiler?.qul_names?.map((item) => { return { value: item, label: item } })}
                            placeholder="...חפש"
                            isClearable
                            isSearchable
                            maxMenuHeight={100} />

                        <label className='ml-3 w-1/6' htmlFor="qul_name">שם ההדרכה</label>
                    </div>
                    <div className='flex justify-end items-center'>
                        <Select className=' w-2/3 text-right'
                            type="text"
                            id="cluster_name"
                            name="cluster_name"
                            value={dataFillter?.cluster_name ? { value: dataFillter.cluster_name, label: dataFillter.cluster_name } : null}
                            onChange={(e) => handleChange(e, 'cluster_name')}
                            options={datafiler?.cluster_names?.map((item) => { return { value: item, label: item } })}
                            placeholder="...חפש"
                            isClearable
                            isSearchable
                            maxMenuHeight={100} />




                        <label className='ml-3 w-1/6' htmlFor="cluster_name">אשכול</label>
                    </div>
                    <div className='flex justify-end items-center'>



                        <Select className=' w-2/3 text-right'
                            type="text"
                            id="paying_id"
                            name="paying_id"
                            value={dataFillter?.paying_id ? { value: paying_label, label: paying_label } : null}
                            onChange={(e) => handleChange2(e, 'paying_id')}
                            options={datafiler?.payingCustomers?.map((item) => { return { value: item.id, label: item.name } })}
                            placeholder="...חפש"
                            isClearable
                            isSearchable
                            maxMenuHeight={100} />


                        <label className='ml-3 w-1/6' htmlFor="clientName">שם הלקוח המשלם</label>
                    </div>
                </div>
            </div>
        </Fillter>
    )
}

export default ReportTrainingDaysFilter