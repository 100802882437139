import React, { useEffect, useState } from 'react'
import { HeaderTable } from '../../HeaderTable'
import DataTable from 'react-data-table-component'
import axios from 'axios'

const Contacts = ({row}) => {
    const [fetchData, setFetchData] = useState([])
    const [loading, setloading] = useState(true)
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1)
   const GET_URL = `admin/setting/contacts/${row?.id}`
   console.log(row) 


    const getDataTable = async (page) => {
        setloading(true)
        await axios.get(GET_URL, {
            params: {
                "page": page
                , "size": perPage,
            }
        })
            .then(response => {
                if (response.status == 200) {
                    setFetchData(response?.data)
                    // setdataForfilterScrach(response?.data)
                    setTotalRows(response.data.totalElements);
                }
            })
        setloading(false)

    }




    const handlePerRowsChange = async (newPerPage) => {
        setPerPage(newPerPage);

    }

    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     setDataFillter({
    //         ...dataFillter,
    //         [name]: value
    //     });
    // }
    const handlePageChange = (page) => {
        // fetchUsers(page);
        // getData(page);
        setCurrentPage(page);
    };
    useEffect(() => {
        getDataTable(currentPage - 1)
    }, [])


    const columns = [

        {
            name: "פעולות",
            // grow: 2,
            selector: (row) => row.product_code,
            style: {
                fontSize: '10px',
                justifyContent: 'center',
                
                // width: '100px'
            },
            sortable: true,
        },


        {
            name: "תפקיד",
            // grow: 3,
            selector: (row) => row.office,
            style: {
                fontSize: '10px',
                justifyContent: 'center',
                // width: '100px'
            },
            sortable: true,


        },
        {
            name: "אימייל",
            // grow: 3,
            selector: (row) => row?.email,
            style: {
                fontSize: '10px',
                justifyContent: 'center',
                // width: '100px'
            },
            sortable: true,
        },

        {
            name: "טלפון",
            cell: row => row.phone,

            style: {
                fontSize: '10px',
                justifyContent: 'center',

            },
            // grow: 3,


        },

        {
            name: "שם איש קשר",
            cell: row => row.name,

            style: {
                fontSize: '10px',
                justifyContent: 'center',

            },
            // grow: 2,


        },
       



    ];



    const customStyles = {
        header: {
            style: {

                justifyContent: 'center',

            },
        },
        id: {
            style: {
                '&:2': { backgroundColor: '#EEEEEE' },
            }
        },
        headRow: {
            style: {
                justifyContent: 'center',
                borderTopStyle: 'solid',
                borderTopWidth: '1px',
                // borderTopColor: defaultThemes.default.divider.default,
            },
        },
        headCells: {
            style: {
fontSize: '10px',
                justifyContent: 'center',
                borderRightStyle: 'solid',
                borderRightWidth: '1px',
                // borderRightColor: defaultThemes.default.divider.default,

            },
        },
        cells: {
            style: {
                '&:not(:last-of-type)': {
                    
                    borderRightStyle: 'solid',
                    borderRightWidth: '1px',
                    // borderRightColor: defaultThemes.default.divider.default,
                },
            },
        },
        rows: {
            style: {
                backgroundColor: '#FFFFFF',
                '&:active': {
                    backgroundColor: '#EEEEEE',
                },
            },
        },
    }









    return (
        <div className='w-full p-3' style={{direction:"ltr"}}>

            <HeaderTable active={"אנשי קשר"}></HeaderTable>

            <DataTable columns={columns} data={fetchData} pagination
                customStyles={customStyles}
                paginationServer
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                progressPending={loading} className='border' 
               >

            </DataTable>


        </div>
    
  )
}

export default Contacts