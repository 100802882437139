import './App.css';
import Login from './pages/Login';
import Main from './pages/Main'
import Members from './pages/Members'
import Tranings from './pages/Tranings'
import Adminstration from './pages/Adminstration';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import HomePage from './pages/HomePage';
import Signup from './pages/Signup';
import ErrorPage from './pages/ErrorPage';
import RegistrationTrainingDays from './pages/RegistrationTrainingDays';
import { useState } from 'react';

function App() {
  const [hide,setHide]=useState("")
  const router = createBrowserRouter([
    {
      path: "",
      element: <Main />,
      errorElement: <ErrorPage/>,
      children: [
        // {
        //   path: "/Home",
        //   element: <Home/>,
        // },

        {
          path: "",
          element: <HomePage />,
        },
        {
          path: "members",
          element: <Members setHide={setHide}/>,
        },
        {
          path: "tranings",
          element: <Tranings setHide={setHide} />,
        },
        {
          path: "adminstration",
          element: <Adminstration setHide={setHide}/>
        },
        
        {
          path: "registrationTrainingDays/:corseId",
          element: <RegistrationTrainingDays/>
        },
        {
          path: "registrationTrainingDays",
          element: <RegistrationTrainingDays/>
        },

        

        // {
        //   path: "dashboard",
        //   element: <Dashboard />,
        //   loader: ({ request }) =>
        //     fetch("/api/dashboard.json", {
        //       signal: request.signal,
        //     }),
        // },
        // {
        //   element: <AuthLayout />,
        //   children: [
        //     {
        //       path: "login",
        //       element: <Login />,
        //       loader: redirectIfUser,
        //     },
        //     {
        //       path: "logout",
        //       action: logoutUser,
        //     },
        //   ],
        // },
      ],
    },
    {
      path: "/login",
      element: <Login/>
    },
    {
      path: "/Signup",
      element: <Signup/>
    }
  
  ]);


  return (
    // <div className={`App font-sans text-xs relative h-screen  ${hide!=""?"overflow-hidden":""}`}>
    <div className={`App font-sans text-xs relative h-screen`}>
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}
      {/* <Login/> */}
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
