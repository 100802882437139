import React from 'react'
import ramdor from '../assets/logoOz.png'
import logoTop from '../assets/ismb_logo.png'
import CardLogin from '../components/CardLogin'
const Login = () => {
    return (
        <div className='bg-black min-h-screen flex flex-col items-center p-3'>
            <img src={ramdor} alt="" className='w-[20%]'/>
            <img src={logoTop} alt="" className='w-[15%] mt-4' />
            <CardLogin></CardLogin>

            {/* <label className='text-[#5cfaff] text-5xl' style={{textShadow:"#5cfaff 0px 0px 10px,#5cfaff 0px 0px 20px,#5cfaff 0px 0px 40px,#5cfaff 0px 0px 80px"}}>התחברות</label> */}
        </div>
    )
}

export default Login