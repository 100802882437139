
import React, { useState, useEffect } from 'react'
import DataTable from 'react-data-table-component'
import axios from 'axios'

const ReDataTable = ({columns,getData}) => {
    const [fetchData, setFetchData] = useState([])
    const [loading, setloading] = useState(true)
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    const getDataTable = async (page) => {


        
        const [result1, result2] = await getData(page,perPage)
        setFetchData(result1);
        setTotalRows(result2); 
        setloading(false)
        console.log(fetchData)
      };

    // const getDataTable = async (page) => {


    //     await axios.get(GET_URL, {
    //         params: {
    //             "page": page
    //             , "size": perPage,
    //         }
    //     })
    //         .then(response => {
    //             setFetchData(response?.data.resMemberRegisters)
    //             setTotalRows(response?.data?.totalElements);
    //         })
    //     setloading(false)
    //     console.log(fetchData)
    // }
    useEffect(() => {
        getDataTable(currentPage - 1)
    }, [currentPage, perPage,getData])

    const handlePerRowsChange = async (newPerPage) => {
        setPerPage(newPerPage);

    }


    const handlePageChange = (page) => {

        setCurrentPage(page);
    };

    const customStyles = {
        header: {
            style: {

                justifyContent: 'center',

            },
        },
        id: {
            style: {
                '&:2': { backgroundColor: '#EEEEEE' },
            }
        },
        headRow: {
            style: {
                justifyContent: 'center',
                borderTopStyle: 'solid',
                borderTopWidth: '1px',
                // borderTopColor: defaultThemes.default.divider.default,
            },
        },
        headCells: {
            style: {

                justifyContent: 'center',
                borderRightStyle: 'solid',
                borderRightWidth: '1px',
                // borderRightColor: defaultThemes.default.divider.default,

            },
        },
        cells: {
            style: {
                '&:not(:last-of-type)': {

                    borderRightStyle: 'solid',
                    borderRightWidth: '1px',
                    // borderRightColor: defaultThemes.default.divider.default,
                },
            },
        },
        rows: {
            style: {
                backgroundColor: '#FFFFFF',
                '&:active': {
                    backgroundColor: '#EEEEEE',
                },
            },
        },
    }


    return (
        <div style={{direction:'ltr'}}> <DataTable
            columns={columns}
            data={fetchData} 
            pagination 
            customStyles={customStyles}
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            progressPending={loading} className='border' >

        </DataTable></div>
    )
}

export default ReDataTable