import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Modall from '../../Modall';

const GET_URL = 'admin/qualification/instructor'


const ModalNewGuides = ({ showModal, children, handleColse, getDepartment, row = "" }) => {
    const [previewUrl, setPreviewUrl] = useState('');
    const [formData, setFormData] = useState({
        fullname: '',
        identity: '',
        phone: "",
        urlimage: '',
    });
    const [file, setFile] = useState(null)
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        console.log(e.target)
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const validate = () => {
        const newErrors = {};
        if (!formData.fullname) newErrors.fullname = 'שדה חובה';
        if (!formData.identity) newErrors.identity = 'שדה חובה';
        if (!formData.phone) newErrors.phone = 'שדה חובה';
        if (!formData.urlimage) newErrors.file = 'שדה חובה';


        return newErrors;
    };

    const handleSubmit = () => {
        // e.preventDefault();
        const validationErrors = validate();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            // Submit the form data
            console.log(formData)
            addNewGuides()
        }
    };

    useEffect(() => {
        if (row) {
            setFormData(
                {
                    'fullname': row.name,
                    'identity': row.identity,
                    'phone': row.phone,
                    'urlimage': `https://archivebureau-tests.s3.eu-west-3.amazonaws.com/${row.imgSignature}`,
                })
            setPreviewUrl(`https://archivebureau-tests.s3.eu-west-3.amazonaws.com/${row.imgSignature}`)
            // setFile(row.imgSignature)
        }
    }, [])


    const handleFileChange = (event) => {
        console.log(event, event.target.value)
        const { name } = event?.target
        const file1 = event.target.files[0];
        setFile(file1)
        setFormData({
            ...formData,
            [name]: event.target.value
        })
        if (file1) {



            const reader = new FileReader();
            reader.onload = (e) => {
                setPreviewUrl(e.target.result);

            };

            reader.readAsDataURL(file1);


            //  reader.onload = (e) => {
            //     console.log( e.target.result)
            //     setFormData({
            //         ...formData,
            //         'urlimage': e.target.result
            //     });;
            //     reader.readAsDataURL(file1);
            // };

            // Extracting only the file name
            //   setFormData({
            //     ...formData,
            //     [name]: event.target.result
            // });
            //   setImageFileName(file.name);
        }
    };

    useEffect(() => { console.log(previewUrl) }, [previewUrl])

    const handleEditClass = async (id) => {
        const validationErrors = validate();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            const formData1 = new FormData();
            formData1.append('instructorDetails', JSON.stringify({
                "name": formData.fullname,
                "identity": formData.identity,
                "phone": formData.phone
            }));
            formData1.append('img_Signature', file);
            const response = await axios.put(`${GET_URL}/${id}`, formData1
                ,
                {
                    headers: { 'Content-Type': 'multipart/form-data' }
                })
            if (response.status == 200) {
                // setnamecalss("")
                getDepartment()
                handleColse()
                console.log("Edit")
            }






            // await axios.put(`${GET_URL}/${id}`, JSON.stringify({
            //     "name": formData.name,
            //     "identity": formData.id,
            //     "shippingAddress": formData.shippingAddress,
            //     "postalCode": formData.postcode,
            //     "primaryEmailReceipts": formData.primaryEmailReceipts,
            //     "attendanceBox": 0
            // }), {
            //     headers: { 'Content-Type': 'application/json' }
            // }).then(response => {
            //     if (response.status == 200) {
            //         // setnamecalss("")
            //         getDepartment()
            //         handleColse()
            //         console.log("Edit")
            //     }
            // })
        }
    }

    const addNewGuides = async () => {

        const formData1 = new FormData();
        formData1.append('instructorDetails', JSON.stringify({
            "name": formData.fullname,
            "identity": formData.identity,
            "phone": formData.phone
        }));
        formData1.append('img_Signature', file);
        const response = await axios.post(GET_URL, formData1

            ,
            {
                headers: { 'Content-Type': 'multipart/form-data' }
            })



        // const response = await axios.post(GET_URL,{
        //     [{'instructorDetails':{ 
        //     "name": formData.fullname,
        //     "identity": formData.identity,
        //     "phone": formData.phone}},
        //     "img_Signature": file]
        // }     
        // , {
        //     headers: { 'Content-Type': 'multipart/form-data' }
        // })
        console.log(response)
        if (response?.status == 200) {
            // setnamecalss("")
            getDepartment()
            handleColse()
        }
        else {
            console.log("error add class")
        }
    }

    return (
        <Modall showModal={showModal}>
            <div className=' w-full divide-y-2  divide-slate-500'>
                <div className='w-full bg-[#0d406b] flex justify-end'>
                    <button className='p-1 px-2 m-3 border bg-slate-500 hover:bg-slate-200' onClick={() => {
                        handleColse()
                    }} >x</button>
                </div>


                <form className='w-full p-5 flex flex-col  text-center text-sm'>
                    <div className='w-full pt-2 flex justify-end'>

                        <input
                            className=' w-1/2 border  text-right'
                            type="text"
                            id="fullname"
                            name="fullname"
                            value={formData.fullname}
                            onChange={handleChange}
                        />
                        <label htmlFor="fullname " className='w-[30%]'><p className='ml-2'><span className='text-red-600'>*</span>שם מלא</p></label>

                    </div>
                    {errors.fullname && <span className="error">{errors.fullname}</span>}

                    <div className='w-full pt-2 flex justify-end'>

                        <input
                            className='border w-1/2 text-right'
                            type="text"
                            id="identity"
                            name="identity"
                            value={formData.identity}
                            onChange={handleChange}
                        />
                        <label htmlFor="identity" className='w-[30%]'><p className='ml-2'><span className='text-red-600'>*</span>תז</p></label>

                    </div>
                    {errors.identity && <span className="error">{errors.identity}</span>}

                    <div className="w-full pt-2 flex justify-end">

                        <input
                            className='border w-1/2 text-right'
                            type="text"
                            id="phone"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                        />
                        <label htmlFor="phone" className='w-[30%]'><p className='ml-2'><span className='text-red-600'>*</span>טלפון</p></label>

                    </div>
                    {errors.phone && <span className="error">{errors.phone}</span>}


                    <div className="w-full pt-2 flex justify-end">

                        <input
                            className='border w-1/2 h-fit '
                            type="file"
                            accept='image/*'
                            id="urlimage"
                            name="urlimage"
                            // value={formData.urlimage}
                            onChange={handleFileChange}
                        />
                        <label htmlFor="urlimage" className='w-[30%]'><p className='ml-2'><span className='text-red-600'>*</span>חתימה (העלאת הקובץ)</p></label>

                    </div>



                    <div className="w-full pt-2 flex justify-end">

                        <input
                            className='border w-1/2 text-right bg-slate-300'
                            type="text"
                            id="urlimage"
                            name="urlimage"
                            value={formData.urlimage}
                        // disabled
                        />
                        <label htmlFor="urlimage" className='w-[30%]'><p className='ml-2'><span className='text-red-600'>*</span>חתימה (הקישור)</p></label>

                    </div>
                    {/* {errors.urlimage && <span className="error">{errors.urlimage}</span>} */}
                    {errors.file && <span className="error">{errors.file}</span>}
                    {<img className='w-[200px] m-4 mb-0 flex self-end' src={previewUrl} />}
   



                </form>
                <div className='w-full p-2   flex justify-end'>
                    <button className='border bg-green-500 hover:bg-green-300 p-1 px-3' onClick={() => {

                        row ? handleEditClass(row.id) : handleSubmit()

                        // handleSubmit()


                    }}>שמור</button>
                    <button className='border bg-slate-700 hover:bg-slate-500 p-1 px-3' onClick={() => {
                        // reset()
                        // setShowModal(false)
                        handleColse()
                    }}>בטל</button>
                </div>
            </div>

        </Modall>
    )

}

export default ModalNewGuides