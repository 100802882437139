import React from 'react'

const ButtonNew = ({active,setModalIsOpen}) => {
  
  return (
    <div className='border border-solid rounded-t-md  border-[#0d406b] '>
       
    <div className='bg-[#0d406b] text-right text-white font-semibold text-[20px] p-2 font-sans grid grid-cols-2 rounded-t-lg border items-center  '>
    <button className='bg-[#1e8e2b] border border-[#1a7925] text-white w-fit h-fit flex justify-self-end self-center p-2 px-3 rounded-lg' onClick={()=>{setModalIsOpen("New")}}>
      חדש+
    </button>
    <p className='' >{active}</p>
    
    </div>
    </div>
  )
}

export default ButtonNew