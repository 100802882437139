import React, { useState } from 'react'
import SubTab from '../components/SubTab'
import MyTrainingDays from '../components/registrationTrainingDays/MyTrainingDays'

const RegistrationTrainingDays = () => {
    

    const [active, setActive] = useState("ימי הכשירות שלי")
    return (
        <div className='bg-[#f3f3f3] p-4 ' >
            {/* <ul className='flex justify-end m-0 list-none'> */}

            <ul className='flex flex-row-reverse  m-0 list-none  '   >
                <SubTab active={active == "פרטים אישים"} onClick={() => { setActive("") }}>פרטים אישים </SubTab>
                <SubTab active={active == "ימי הכשירות שלי"} onClick={() => { setActive("ימי הכשירות שלי") }}>ימי הכשירות שלי</SubTab>
              
                {/* <SubTab active={active == "כותרת לתעודה"} onClick={() => { setActive("כותרת לתעודה") }}>כותרת לתעודה </SubTab> */}
                {/* <li key={1} className={active == 1 ? 'p-2 m-1 rounded-t-md bg-slate-500' : 'p-2 m-1 rounded-t-md hover:bg-slate-500'} onClick={() => { setActive(1) }}>ימי כשירות</li>
                <li key={2} className={active == 2 ? 'p-2 m-1 rounded-t-md bg-slate-500' : 'p-2 m-1 rounded-t-md hover:bg-slate-500'} onClick={() => { setActive(2) }}>מדריכים</li>
                <li key={3} className={active == 3 ? 'p-2 m-1 rounded-t-md bg-slate-500' : 'p-2 m-1 rounded-t-md hover:bg-slate-500'} onClick={() => { setActive(3) }}>כותרת לתעודה</li> */}
            </ul>
            <div className='bg-white w-full'>
               {/* {active=="ימי כשירות"&&<TrainingDays />} */}
               {active=="ימי הכשירות שלי"&&<MyTrainingDays/>}
               {/* {active=="כותרת לתעודה"&&<Title_certificate/>} */}
            </div>

        </div>
    )
}

export default RegistrationTrainingDays