import axios from "axios";
export default axios.create({
  // baseURL: "https://api.cobmindex.com/api/v1/",
  // baseURL: "http://ec2-35-180-160-9.eu-west-3.compute.amazonaws.com/api/",
  
  
  
  //NOAM
   baseURL: "https://backapi.ismbonline.org/api/",
    

});