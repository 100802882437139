import React,{useEffect,useState} from 'react'
import DataTable from 'react-data-table-component'
import axios from 'axios'
import { HeaderTable } from '../../HeaderTable'
import SubTab from '../../SubTab'


const Status_member = () => {
    
const [fetchData, setFetchData] = useState([])
    const [loading, setloading] = useState(true)
    // const [active,setActive]=useState("חברי לשכה")
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const GET_URL = 'admin/setting/status_member'

    const getForms = async (page) => {
        // setloading(true);
        // await axios.get(`${GET_PAYING_CUSTOMERS_URL}s`).then(response => {
            await axios.get(GET_URL,{
                // params:{"page":page
                //     ,"size":perPage,
                    
                // }
                })
        .then(response => {
            setFetchData(response?.data)
            setTotalRows(response?.data?.totalElements);
        })
        setloading(false)
        console.log(fetchData)}
    

    useEffect(()=>{
        getForms(currentPage-1)
    },[currentPage,perPage])

    const handlePerRowsChange = async (newPerPage) => {
        setPerPage(newPerPage);

    }

   

    const handlePageChange = (page) => {
        // fetchUsers(page);
        setFetchData(page);
        setCurrentPage(page);
      };


      const columns = [
       
        {
            name: "פעולות",
            
            selector: (row) => "",
            style: {
                fontSize: '16px',
                justifyContent: 'center',
                width: '100px'
            },
            sortable: true,
        }
       ,
        {
            name: "תיאור סטטוס",
            grow: 4,
            selector: (row) => row.statusDescription,
            style: {
                fontSize: '16px',
                justifyContent: 'center',
                width: '100px'
            },
            sortable: true,
        }
        

    ]



    const customStyles = {
        header: {
            style: {

                justifyContent: 'center',

            },
        },
        id: {
            style: {
                '&:2': { backgroundColor: '#EEEEEE' },
            }
        },
        headRow: {
            style: {
                justifyContent: 'center',
                borderTopStyle: 'solid',
                borderTopWidth: '1px',
                // borderTopColor: defaultThemes.default.divider.default,
            },
        },
        headCells: {
            style: {

                justifyContent: 'center',
                borderRightStyle: 'solid',
                borderRightWidth: '1px',
                // borderRightColor: defaultThemes.default.divider.default,

            },
        },
        cells: {
            style: {
                '&:not(:last-of-type)': {

                    borderRightStyle: 'solid',
                    borderRightWidth: '1px',
                    // borderRightColor: defaultThemes.default.divider.default,
                },
            },
        },
        rows: {
            style: {
                backgroundColor: '#FFFFFF',
                '&:active': {
                    backgroundColor: '#EEEEEE',
                },
            },
        },
    }


  return (
    <div className='w-full '>
    <ul className='flex flex-row-reverse my-2 m-0 mr-2 list-none  '   >
        <SubTab active={true} >סטטוס לחברי הלשכה</SubTab>
    </ul>
    <HeaderTable  active={"סטטוסים לחברי הלשכה"} ></HeaderTable>
    <DataTable columns={columns} data={fetchData} pagination customStyles={customStyles} 
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        progressPending={loading} className='border' >
        </DataTable>
        </div>
  )
}

export default Status_member