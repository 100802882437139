import React from 'react'

const Footer = () => {
  return (
    <footer className='p-4 bg-[#f5f7fa]'>
        <label>כל הזכויות שמורות ©</label>
        {/* <a href="https://www.ramdor.co.il" target="_blank" className='mx-4'>לרמדור- ניהול פרויקטים בבניה, יומן עבודה, כתב כמויות ועוד</a> */}
        {/* <a href="https://top-group.co.il/group/private-policy-website-hebrew/" target="_blank">| מדיניות פרטיות</a> */}

        </footer>
  )
}

export default Footer