import React, { useEffect, useState } from 'react'
// import { HeaderTable } from '../../HeaderTable'
// import DataTable from 'react-data-table-component'
import axios from 'axios'
import TimeInput from '../../../ui/TimeInput'
import { convertToIsraelTime, formatOnlyDate } from '../../../../util/formatsForDate'




const TrainingDaysDetails = ({ row }) => {
    const [getDetails,setDetDetails]=useState([])
    const GET_URL = `admin/qualification/get_qualification_details/${row?.id}`
    const [loading, setloading] = useState(true)
   
    useEffect(()=>{
        const funcdata =async()=> await axios.get(GET_URL, {
        }).then(response => {
            if (response.status == 200) {
                console.log(response.data)
               setDetDetails (response.data)
            }
        })
        funcdata()
        
    },[])
    // getDetails.then(data => {
    //     console.log(data)})
    //delete const getDataTable = async (page) => {
    //     setloading(true)
    //     await axios.get(GET_URL, {
    //     //    
    //     })
    //         .then(response => {
    //             if (response.status == 200) {
    //                 setFetchData(response?.data)
    //                 // setdataForfilterScrach(response?.data)
    //                 // setTotalRows(response.data.totalElements);
    //             }
    //         })
    //     setloading(false)

    // }


    return (
        <div className='' dir='rtl'>
            <TimeInput/>
            <div className='grid grid-cols-10 ' >
                <div className='col-span-3' >מספר אשכול</div>
                <div className='col-span-7 text-right'>{getDetails?.clusterNumber} </div>
            </div>
            <div className='grid grid-cols-10 ' dir='rtl'>
                <div className='col-span-3'>שם אשכול</div>
                <div className='col-span-7 text-right'>{getDetails?.clusterName} </div>
            </div>
            <div className='grid grid-cols-10 ' dir='rtl'>
                <div className='col-span-3'>מספר יום כשירות</div>
                <div className='col-span-7 text-right'>{getDetails?.qualificationNumber} </div>
            </div>
            <div className='grid grid-cols-10 ' dir='rtl'>
                <div className='col-span-3'>שם יום הכשירות</div>
                <div className='col-span-7 text-right'>{getDetails?.qualificationName} </div>
            </div>
            <div className='grid grid-cols-10 ' dir='rtl'>
                <div className='col-span-3'>תיאור</div>
                <div className='col-span-7 text-right' >{getDetails?.description} </div>
            </div>
            <div className='grid grid-cols-10 ' dir='rtl'>
                <div className='col-span-3'>תאריך התחלה</div>
                <div className='col-span-7 text-right' >{getDetails?.startDate?convertToIsraelTime(getDetails?.startDate):""} </div>
            </div>
            <div className='grid grid-cols-10 ' dir='rtl'>
                <div className='col-span-3'>מספר משתתפים מקסימלי</div>
                <div className='col-span-7 text-right'  >{getDetails?.maximumParticipants} </div>
            </div>
            <div className='grid grid-cols-10 ' dir='rtl'>
                <div className='col-span-3'>מספר משתתפים רשומים</div>
                <div className='col-span-7 text-right' >{getDetails?.registerParticipants} </div>
            </div>
            <div className='grid grid-cols-10 ' dir='rtl'>
                <div className='col-span-3'>מיקום</div>
                <div className='col-span-7 text-right' >{getDetails?.place} </div>
            </div>
            <div className='grid grid-cols-10 ' dir='rtl'>
                <div className='col-span-3'>Latitude</div>
                <div className='col-span-7 text-right' >{getDetails?.latitude} </div>
            </div>
            <div className='grid grid-cols-10 ' dir='rtl'>
                <div className='col-span-3'>Longitude</div>
                <div className='col-span-7 text-right' >{getDetails?.longitude} </div>
            </div>
            <div className='grid grid-cols-10 ' dir='rtl'>
                <div className='col-span-3'>סטטוס</div>
                <div className='col-span-7 text-right' >{getDetails?.statusQualificationDesc} </div>
            </div>
        </div>
    )
}

export default TrainingDaysDetails