import React, { useState } from 'react'
import TrainingDays from '../components/tranings/trainingdays/TrainingDays'
import SubTab from '../components/SubTab'
import Guides from '../components/tranings/guides/Guides'
import Title_certificate from '../components/tranings/title_certificate/Title_certificate'

const Tranings = ({setHide}) => {
    const [active, setActive] = useState("ימי כשירות")
    return (
        <div className='bg-[#f3f3f3] p-4 ' >
            {/* <ul className='flex justify-end m-0 list-none'> */}

            <ul className='flex flex-row-reverse  m-0 list-none  '   >
                <SubTab active={active == "ימי כשירות"} onClick={() => { setActive("ימי כשירות") }}>ימי כשירות </SubTab>
                <SubTab active={active == "מדריכים"} onClick={() => { setActive("מדריכים") }}>מדריכים</SubTab>
                <SubTab active={active == "כותרת לתעודה"} onClick={() => { setActive("כותרת לתעודה") }}>כותרת לתעודה </SubTab>
                {/* <li key={1} className={active == 1 ? 'p-2 m-1 rounded-t-md bg-slate-500' : 'p-2 m-1 rounded-t-md hover:bg-slate-500'} onClick={() => { setActive(1) }}>ימי כשירות</li>
                <li key={2} className={active == 2 ? 'p-2 m-1 rounded-t-md bg-slate-500' : 'p-2 m-1 rounded-t-md hover:bg-slate-500'} onClick={() => { setActive(2) }}>מדריכים</li>
                <li key={3} className={active == 3 ? 'p-2 m-1 rounded-t-md bg-slate-500' : 'p-2 m-1 rounded-t-md hover:bg-slate-500'} onClick={() => { setActive(3) }}>כותרת לתעודה</li> */}
            </ul>
            <div className='bg-white w-full'>
               {active=="ימי כשירות"&&<TrainingDays setHide={setHide} />}
               {active=="מדריכים"&&<Guides setHide={setHide}/>}
               {active=="כותרת לתעודה"&&<Title_certificate setHide={setHide}/>}
            </div>

        </div>
    )
}

export default Tranings