import React, { useState } from 'react'
import MoreOptionsCard from '../../ui/MoreOptionsCard'
import SubTab from '../../SubTab'
import TrainingDaysDetails from './subTab_more_options/TrainingDaysDetails'
import Activityday from './subTab_more_options/Activityday/Activityday'
import SignatureWindows from './subTab_more_options/SignatureWindows'
import RegistersMemberQualification from './subTab_more_options/RegistersMemberQualification/RegistersMemberQualification'
import CollectingSignatures from './subTab_more_options/CollectingSignatures'

const MoreOptions = ({ handleColse, row }) => {
  const [active, setActive] = useState("פרטי ימי הכשירות")

  return (
    <MoreOptionsCard handleColse={handleColse}>
      <div className='w-full p-2 flex flex-col  ' style={{ direction: "rtl" }} >
        <h3 className='font-bold underline font-mono text-lg text-center' >{row?.qualificationName}</h3>
        <div className='bg-[#f3f3f3] p-4 ' >

          <ul className='flex flex-row  m-0 list-none  '   >
            <SubTab active={active == "פרטי ימי הכשירות"} onClick={() => { setActive("פרטי ימי הכשירות") }}>פרטי ימי הכשירות </SubTab>
            <SubTab active={active == "ימי פעילות"} onClick={() => { setActive("ימי פעילות") }}>ימי פעילות</SubTab>
            <SubTab active={active == "ריכוז חלונות חתימה"} onClick={() => { setActive("ריכוז חלונות חתימה") }}>ריכוז חלונות חתימה</SubTab>
            <SubTab active={active == "הרשמות"} onClick={() => { setActive("הרשמות") }}>הרשמות</SubTab>
            <SubTab active={active == "ריכוז חתימות"} onClick={() => { setActive("ריכוז חתימות") }}>ריכוז חתימות</SubTab>
          </ul>
          <div className='bg-white w-full'>
            {active == "פרטי ימי הכשירות" && <TrainingDaysDetails row={row} />}
            {active == "ימי פעילות" && <Activityday row={row}
            //  columns={columns}
            />}
            {active == "ריכוז חלונות חתימה" && <SignatureWindows row={row}/>}
            {active == "הרשמות" && <RegistersMemberQualification row={row}/>}
            {active == "ריכוז חתימות" && <CollectingSignatures row={row}/>}
            {/* {active=="כותרת לתעודה"&&<Title_certificate/>} */}
          </div>
        </div>
      </div>
    </MoreOptionsCard>
  )
}

export default MoreOptions