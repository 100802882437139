import React from 'react'

export const HeaderTable = ({active}) => {

  return (
    <div className='border border-solid rounded-t-md border-[#0d406b]  '>
       
    <div className='bg-[#0d406b] text-right text-white font-semibold text-[20px] p-4 font-sans flex justify-end rounded-t-lg border  '>
    
    <p>{active}</p>
    
    </div>
    </div>
  )
}
