import React, { useEffect, useState } from 'react'
// import axios from 'axios'
import Fillter from '../../Fillter'
import Select from "react-select"
import axios from 'axios'
// const GET_Filter = 'admin/qualification/before_filter_Qualification_day'
// const GET_PAYING_CUSTOMERS_URL = 'admin/setting/paying_customers_page'


const TrainingDaysFilter = ({setDataFillterout,datafiler }) => {
  
    const [dataFillterlabel, setDataFillterlabel] = useState({
        "statusQId": "",
        "instructorId": "",
        "clusterId": "",
    })



    const [dataFillter, setDataFillter] = useState({
        "statusQId": "",
        "instructorId": "",
        "place": "",
        "name": "",
        "dateSt": "",
        "dateEn": "",
        "number": "",
        "clusterId": "",
    })
    const restart = async () => {
        setDataFillter(  {
            "statusQId": "",
            "instructorId": "",
            "place": "",
            "name": "",
            "dateSt": "",
            "dateEn": "",
            "number": "",
            "clusterId": "",
        })
        setDataFillterout(
            {
                "statusQId": "",
                "instructorId": "",
                "place": "",
                "name": "",
                "dateSt": "",
                "dateEn": "",
                "number": "",
                "clusterId": "",
            }
        )
        // await getPaying_customersFilter()
    }
    const handleChange = (e, name) => {
        setDataFillter({
            ...dataFillter,
            [name]: e?.value
        });
        if (name=="statusQId"||name=="instructorId"||name=="clusterId")
            {
                setDataFillterlabel({
                    ...dataFillterlabel,
                    [name]: e?.label
                });
            }
    }
   


    const formatForValue =(value)=>{
        const [day, month, year] = value.split('-');
        return(`${year}-${month}-${day}`)
    }
    const formatForDic =(value)=>{
        const [year, month, day] = value.split('-');
        return(`${day}-${month}-${year}`)
    }




    const handleChangeDate = (e) => {
        const { name, value } = e.target;
    
        setDataFillter({
            
            ...dataFillter,
            [name]: formatForDic(value)
        });
    }
    
    const actionFilter=()=>{
        setDataFillterout(dataFillter)
    }

    // const [datafiler, setDatafiler] = useState("");
    
    // const getFilter = async () => {

    //     await axios.get(GET_Filter, {
    //     })
    //         .then(response => {
    //             setDatafiler(response?.data)

    //         })

    // }
    // useEffect(() => {
    //     getFilter()
    // }, [])


    return (
        <Fillter actionFilter={actionFilter} restart={restart}>
            <div className='grid grid-cols-2 '>
                <div className='grid col-span-1 gap-y-2'>
                    <div className='flex justify-end items-center'>
                 
                        <Select className='border border-black w-2/3 text-right' 
                          value={dataFillter?.name ? { value: dataFillter.name, label: dataFillter.name } : null}
                          onChange={(e) => handleChange(e, 'name')}
                         options={datafiler?.resNamesQ?.map((item) => { return { value: item, label: item } })}
                            placeholder="...חפש"
                            isClearable
                            isSearchable
                            maxMenuHeight={100} 
                        />
                         
               
                        <label className='ml-3 w-1/6' htmlFor="identity">שם יום הכשירות</label>
                    </div>
                    <div className='flex justify-end items-center'>
                        <Select className='border border-black w-2/3 text-right ' 
                            type="text"
                            id="place"
                            name="place"
                            
                            value={dataFillter?.place ? { value: dataFillter.place, label: dataFillter.place } : null}
                            onChange={(e) => handleChange(e, 'place')}
                            
                            options={datafiler?.resPlaceQ?.map((item) => { return { value: item, label: item } })}
                            placeholder="...חפש"
                            isClearable
                            isSearchable
                            maxMenuHeight={100} 
                            />
                            
                            

                        <label className='ml-3 w-1/6' htmlFor="identity">מיקום</label>
                    </div>
                    <div className='flex justify-end items-center'>
                        <Select className='border border-black w-2/3 text-right ' 
                            type="text"
                            id="number"
                            name="number"
                            value={dataFillter?.number ? { value: dataFillter.number, label: dataFillter.number } : null}
                            onChange={(e) => handleChange(e, 'number')}
                            
                            options={datafiler?.resNumberQ?.map((item) => { return { value: item, label: item } })}
                            placeholder="...חפש"
                            isClearable
                            isSearchable
                            maxMenuHeight={100} 
                            
                            />

                        <label className='ml-3 w-1/6' htmlFor="resPlaceQ">מספר יום הכשירות</label>
                    </div>

                </div>
                <div className='grid col-span-1 '>
                    <div className='flex justify-end items-center'>
                        <Select className='border border-black w-2/3 text-right ' 
                            type="text"
                            id="clusterId"
                            name="clusterId"
                            value={dataFillterlabel?.clusterId ? { value: dataFillterlabel.clusterId, label: dataFillterlabel.clusterId } : null}
                            onChange={(e) => handleChange(e, 'clusterId')}
                            options={datafiler?.resClusterQS?.map((item) => { return { value: item.id, label: item.display } })}
                            placeholder="...חפש"
                            isClearable
                            isSearchable
                            maxMenuHeight={100} />


                        <label className='ml-3 w-1/6' htmlFor="instructorId">אשכול</label>
                    </div>
                    <div className='flex justify-end items-center'>
                        <Select className='border border-black w-2/3 text-right ' 
                            type="text"
                            id="statusQId"
                            name="statusQId"
                            value={dataFillter?.statusQId ? { value: dataFillter.statusQId, label: dataFillter.statusQId } : null}
                            onChange={(e) => handleChange(e, 'statusQId')}
                            options={datafiler?.resStatusQualificationQS?.map((item) => { return { value: item.id, label: item.desc } })}
                            placeholder="...חפש"
                            isClearable
                            isSearchable
                            maxMenuHeight={100} />





                        <label className='ml-3 w-1/6' htmlFor="statusQId">סטטוס</label>
                    </div>
                    <div className='flex justify-end items-center'>
                        <Select className='border border-black w-2/3 text-right ' 
                            type="text"
                            id="instructorId"
                            name="instructorId"
                            value={dataFillter?.instructorId ? { value: dataFillter.instructorId, label: dataFillter.instructorId } : null}
                            onChange={(e) => handleChange(e, 'instructorId')}
                            options={datafiler?.resInstructorQS?.map((item) => { return { value: item.id, label: item.desc } })}
                            placeholder="...חפש"
                            isClearable
                            isSearchable
                            maxMenuHeight={100} />
                            


                        <label className='ml-3 w-1/6' htmlFor="name">שם המדריך</label>
                    </div>


                </div>

            </div>
            {/* className="p-2 outline-none focus:ring-2 focus:ring-blue-500 w-full" */}
            <div className='w-full m-2 flex justify-end items-center'>
                <div className='w-1/3  flex justify-end '>
                    <input className='border border-black  text-right  mr-2' placeholder="עד תאריך"
                        type="Date"
                        id="dateSt"
                        name="dateSt"
                        data-date-format="DD-MM-YYYY"
                        value={formatForValue(dataFillter.dateSt)}
                        onChange={handleChangeDate} />


                    <input className='border  border-black text-right ' placeholder='מתאריך'
                        type="date"
                        id="dateEn"
                        name="dateEn"
                        value={formatForValue(dataFillter.dateEn)}
                        onChange={handleChangeDate} />

                </div>
                <label className=' w-1/6' htmlFor="date">תאריך התחלה</label>
            </div>
        </Fillter>
    )
}

export default TrainingDaysFilter