import React, { useEffect, useState, useRef } from 'react'
import Modall from '../Modall';
import { FaUpload } from 'react-icons/fa';

import Select from "react-select"
import axios from 'axios'
// const GET_URL = 'admin/member/init_filter_member'
const GET_URL = 'admin/member/init_member_create'
const GET_CREACT = 'admin/member/create_member'
const ModalNewMembr = ({ getDataFILTER, getDepartment, handleColse, row = "" }) => {
    const arrtel = ["קידומת", "050", "051", "052", "053", "054", "055", "057", "058", "059"]
    const fileInputRef = useRef(null);
    const imgInputRef = useRef(null);
    const signInputRef = useRef(null);
    // const [statusList, setstatusesList] = useState("");
    const [payList, setpayList] = useState("");
    const [citysList, setcitysList] = useState([]);
    const [streetList, setstreetList] = useState([]);
    const [previewUrl, setPreviewUrl] = useState('https://ismb.tech-bar.co.il/resources/images/imagelists/default-image.png');
    // const [fileUrl, setfilewUrl] = useState('');
    const [signUrl, setsignUrl] = useState('https://ismb.tech-bar.co.il/resources/images/imagelists/default-image.png');
    // const formattedClients = 0
    // = dataForfilterScrach?.map(client => ({
    //     value: client.identity,
    //     label: client.identity,
    //   }));

    const [formData, setFormData] = useState(row ? {

        dateRegister: row.dateRegister,
        number: row.number,
        status: row.status,
        firstName: row.firstName,
        lastName: row.lastName,
        idNumber: row.idNumber,
        dateBorn: row.dateBorn,
        job: row.job,
        payingCustomerId: row.payingCustomerId,
        street: row.street,
        houseNumber: row.houseNumber,
        apartmentNumber: row.apartmentNumber,
        city: row.city
        ,
        email: row.email,
        additionalPhone: row.additionalPhone,
        nsNumber: row.nsNumber
    }
        :
        {
            dateRegister: "הרשמה",
            number: '',
            status: '',
            firstName: '',
            lastName: '',
            idNumber: '',
            dateBorn: "2024/07/07",
            job: '',
            payingCustomerId: '',
            street: '',
            houseNumber: '',
            apartmentNumber: '',
            city: '',
            email: '',
            additionalPhone: '',
            nsNumber: ''
        }
    );


    const handleTakePhotoClick = (ref) => {
        // Trigger the file input element to open the camera
        if (ref == "fileInputRef")

            fileInputRef.current.click();
        if (ref == "imgInputRef")
            imgInputRef.current.click();

        if (ref == "signInputRef")
            signInputRef.current.click()
    };


    const getPayList = async () => {

        await axios.get(GET_URL, {
        })
            .then(response => {
                setpayList(response?.data)

            })


    }

    const getCity = async () => {
        // await axios.get('https://data.gov.il/api/3/action/datastore_search', {

        //         params: { resource_id:"5c78e9fa-c2e2-4771-93ff-7f400a12f7ba",q:"", limit:"100" },
        //         responseType: "json",
        //       }).then(response => {
        //     if (response.status == 200) {
        //         console.log("response?.data")
        //     }
        // })
        await axios.get('admin/setting/all_city', {
        }).then(response => {
            if (response.status == 200) {
                setcitysList(response?.data)
            }
        })
    }
    const getStreets = async () => {

        if (formData.city) {
            await axios.get(`admin/setting/all_street/${formData.city}`, {
            }).then(response => {
                if (response.status == 200) {
                    setstreetList(response?.data)
                }
            })
        }
        else { setstreetList([]) }
    }

    useEffect(() => {
        getPayList()
        getCity()
    }, [])
    useEffect(() => {
        getStreets()
        formData.street = ""
    }, [formData.city])


    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            background: '#fff',
            borderColor: '#9e9e9e',
            minHeight: '20px',
            height: '20px',
            boxShadow: state.isFocused ? null : null,
        }),

        valueContainer: (provided, state) => ({
            ...provided,
            height: '20px',
            padding: '0 6px'
        }),

        input: (provided, state) => ({
            ...provided,
            margin: '0px',
        }),
        indicatorSeparator: state => ({
            display: 'none',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '20px',
        }),
    };


    const [dataFiles, setDataFiles] = useState({
        file: null,
        fileName: '',
        fileImg: null,
        fileImgName: '',
    })




    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleChangeSelect = (selectedOption, name) => {
        setFormData({
            ...formData,
            [name]: selectedOption ? selectedOption.value : ""
            ,
        })
    }


    const handleDateChange = (date, name) => {
        setFormData({
            ...formData,
            personalDetails: {
                ...formData.personalDetails,
                [name]: date,
            }
        });
    };

    const handleImgChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setPreviewUrl(e.target.result)
            }
            reader.readAsDataURL(file);


            setDataFiles({
                ...dataFiles,
                fileImg: file,
                fileImgName: file ? file.name : '',
            })
        }
    };
    const handleFileChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            // setfilewUrl(file)



            setDataFiles({
                ...dataFiles,
                file: file,
                fileName: file ? file.name : '',
            })
        }
    };

    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     // Handle form submission
    //     console.log(formData);
    // };


    const addNewMember = async () => {

        const formData1 = new FormData();
        formData1.append('memberDetails', JSON.stringify(
            { ...formData }
        ));
        formData1.append('img_face_picture', dataFiles.fileImg);
        formData1.append('img_certificate', dataFiles.file);
        formData1.append('img_signature', dataFiles.fileImg);
        const response = await axios.post(GET_CREACT, formData1

            ,
            {
                headers: { 'Content-Type': 'multipart/form-data' }
            })



        // const response = await axios.post(GET_URL,{
        //     [{'instructorDetails':{ 
        //     "name": formData.fullname,
        //     "identity": formData.identity,
        //     "phone": formData.phone}},
        //     "img_Signature": file]
        // }     
        // , {
        //     headers: { 'Content-Type': 'multipart/form-data' }
        // })
        console.log(response)
        if (response?.status == 200) {
            // setnamecalss("")
            getDepartment()
            getDataFILTER()
            handleColse()
        }
        else {
            console.log("error add class")
        }
    }

    return (
        <div className='absolute top-0 h-screen overflow-y-auto left-0 flex flex-col items-center pt-2 w-full drop-shadow-2xl shadow-2xl bg-[#FDFFFC]/50 '
            onClick={() => {
                // handleColse()
            }}>
            <div className='bg-white w-1/2 rounded-lg divide-y-2  divide-slate-500 border-0 border-emerald-50 '>
                <div className='w-full bg-[#0d406b] rounded-t-md flex justify-end border-[#0d406b] border-2'>
                    <button className='p-1 px-2 m-3 border bg-slate-500 hover:bg-slate-200' onClick={() => {
                        handleColse()
                    }} >x</button>
                </div>
                <form className='w-full  p-2 flex flex-col  ' style={{ direction: "rtl" }} >
                    <div>
                        <h3 className='font-bold underline font-mono text-lg text-right' >פרטים אישיים</h3>
                        <div className='grid grid-cols-10'>
                            <label className='grid col-span-3'>תאריך הרשמה</label>
                            <input
                                className='grid col-span-3'
                                type='date'
                                selected={formData?.dateRegister}
                                onChange={(date) => handleDateChange(date, 'dateRegister')}
                                dateFormat="dd/MM/yyyy"
                            />
                        </div>
                        <div className='grid grid-cols-10'>
                            <label className='grid col-span-3'>מספר חבר</label>
                            <input
                                className='grid col-span-5 border'
                                type="text"
                                name="number"
                                value={formData?.number}
                                onChange={handleChange}
                            />
                        </div>
                        <div className='grid grid-cols-10'>
                            <label className='grid col-span-3'>סטטוס</label>

                            <Select className='  grid col-span-5 p-0 text-right'

                                options={payList?.statuses?.map((item) => { return { value: item?.statusDescription, label: item?.statusDescription } })}
                                placeholder="חפש..."
                                isClearable
                                isSearchable
                                styles={customStyles}
                                value={ formData?.status?{ value: formData?.status?.statusDescription, label:formData?.status?.statusDescription}:null}
                                maxMenuHeight={100}
                                onChange={(e) => handleChangeSelect(e, 'status')}
                            ></Select>
                            {/* <input
                                className='grid col-span-5  border'
                                type="text"
                                name="status"
                                value={formData.status}
                                onChange={handleChange}
                            /> */}
                        </div>
                        <div className='grid grid-cols-10'>
                            <label className='grid col-span-3' >שם פרטי</label>
                            <input
                                className='grid col-span-5  border'
                                type="text"
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleChange}
                            />
                        </div>
                        <div className='grid grid-cols-10'>
                            <label className='grid col-span-3'>שם משפחה</label>
                            <input
                                className='grid col-span-5  border'
                                type="text"
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleChange}
                            />
                        </div>
                        <div className='grid grid-cols-10'>
                            <label className='grid col-span-3'>ת.ז.</label>
                            <input
                                className='grid col-span-5  border'
                                type="text"
                                name="idNumber"
                                value={formData.idNumber}
                                onChange={handleChange}
                            />
                        </div>
                        <div className='grid grid-cols-10'>
                            <label className='grid col-span-3'>תאריך לידה </label>
                            <input
                                className='grid col-span-5  border'
                                type='date'
                                selected={formData?.dateBorn}
                                onChange={(date) => handleDateChange(date, 'dateBorn')}
                                dateFormat="dd/MM/yyyy"
                            />
                        </div>
                        <div className='grid grid-cols-10'>
                            <label className='grid col-span-3'>מקום עבודה</label>
                            <input
                                className='grid col-span-5  border'
                                type="text"
                                name="job"
                                value={formData.job}
                                onChange={handleChange}
                            />
                        </div>
                        <div className='grid grid-cols-10'>
                            <label className='grid col-span-3' >לקוח משלם</label>

                            <Select className='  grid col-span-5 p-0 text-right'
                                // options={formattedClients} value={""} 
                                // value={dataFillter.lastName?
                                //     { value: dataFillter.lastName, label: dataFillter.lastName }:null}
                                // onChange={(e) => handleChange(e, 'lastName')}
                                options={payList?.scrollPaying?.map((item) => { return { value: item.id, label: item.display } })}
                                // options={id?.lastNames?.map((item) => { return { value: item, label: item } })}
                                placeholder="חפש..."
                                isClearable
                                isSearchable

                                styles={customStyles}
                                maxMenuHeight={100}
                            ></Select>
                        </div>
                    </div>

                    <div>
                        <h3 className='font-bold underline font-mono text-lg text-right'>כתובת</h3>
                        <div className='grid grid-cols-10'>
                            <label className='grid col-span-3'>עיר</label>

                            <Select className='  grid col-span-5 p-0 text-right'
                                options={citysList?.map((item) => { return { value: item, label: item } })}
                                placeholder="חפש..."
                                isClearable
                                isSearchable
                                styles={customStyles}
                                maxMenuHeight={100}
                                // name="city"
                                // value={formData.city}
                                onChange={(e) => handleChangeSelect(e, 'city')}
                            ></Select>
                            {/* <input
                                className='grid col-span-5  border'
                                type="text"
                                name="city"
                                value={formData.city}
                                onChange={handleChange}
                            /> */}
                        </div>
                        <div className='grid grid-cols-10'>
                            <label className='grid col-span-3'>רחוב</label>
                            <Select className='  grid col-span-5 p-0 text-right'
                                options={streetList?.map((item) => { return { value: item, label: item } })}
                                placeholder="חפש..."
                                isClearable
                                isSearchable
                                styles={customStyles}
                                maxMenuHeight={100}
                                // name="street"
                                value={formData.street ?
                                    { value: formData.street, label: formData.street } : null}
                                onChange={(e) => handleChangeSelect(e, 'street')}
                            // value={formData.street}
                            // onChange={handleChange}
                            ></Select>
                            {/* <input
                                className='grid col-span-5 border '
                                type="text"
                                name="street"
                                value={formData.street}
                                onChange={handleChange}
                            /> */}
                        </div>
                        <div className='grid grid-cols-10'>
                            <label className='grid col-span-3'>מספר בית</label>
                            <input
                                className='grid col-span-5  border'
                                type="text"
                                name="houseNumber"
                                value={formData.houseNumber}
                                onChange={handleChange}
                            />
                        </div>
                        <div className='grid grid-cols-10'>
                            <label className='grid col-span-3'>מספר דירה</label>
                            <input
                                className='grid col-span-5  border'
                                type="text"
                                name="apartmentNumber"
                                value={formData.apartmentNumber}
                                onChange={handleChange}
                            />
                        </div>

                    </div>

                    <div>
                        <h3 className='font-bold underline font-mono text-lg text-right' >פרטי התקשרות</h3>
                        <div className='grid grid-cols-10'>
                            <label className='grid col-span-3'>אימייל</label>
                            <input
                                className='grid col-span-5  border'
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                            />
                        </div>
                        <div className='grid grid-cols-10'>
                            <label className='grid col-span-3' >טלפון ראשי</label>
                            <input
                                className='grid col-span-3  border'
                                type="number"
                                name="primaryPhone"
                                // value={formData.contactDetails.primaryPhone}
                                onChange={handleChange}
                            />
                            <select className='border grid col-span-2 '>
                                {
                                    arrtel.map((index) => {
                                        return (<option className='w-full'>
                                            {index}
                                        </option>)
                                    })
                                }
                            </select>
                        </div>
                        <div className='grid grid-cols-10'>
                            <label className='grid col-span-3'>טלפון נוסף (אופציונלי)</label>
                            <input
                                className='grid col-span-3  border'
                                type="number"
                                name="secondaryPhone"
                                // value={formData.contactDetails.secondaryPhone}
                                onChange={handleChange}
                            />
                            <select className='border grid col-span-2 '>
                                {
                                    arrtel.map((index) => {
                                        return (<option className='w-full'>
                                            {index}
                                        </option>)
                                    })
                                }
                            </select>
                        </div>
                    </div>

                    <div>
                        <h3 className='font-bold underline font-mono text-lg text-right' >אישור כשירות</h3>
                        <div className='grid grid-cols-10'>
                            <label className='grid col-span-3'>מספר נ.ש.</label>
                            <input
                                className='grid col-span-5  border'
                                type="text"
                                name="certificationNumber"
                                value={formData.nsNumber}
                                onChange={handleChange}
                            />
                        </div>
                    </div>

                    <div>
                        <h3 className='font-bold underline font-mono text-lg text-right' >קבצים מצורפים</h3>
                        <div className='border-2 border-black bg-[#ccc]  rounded-md py-2 px-1'>
                            <button type="button" className='flex text-[10px] w-fit place-self-center py-1 px-2 items-baseline bg-gray-400  border-black border my-2 rounded-sm text-white cursor-pointer' onClick={() => handleTakePhotoClick("imgInputRef")}>
                                <FaUpload />
                                <p className='mr-2'>העלה קובץ</p>
                            </button>
                            <input type="file" id="fileInput" name="fileInput"
                                onChange={handleImgChange}
                                style={{ display: 'none' }}
                                ref={imgInputRef}
                                accept="image/*"
                            />


                            {dataFiles.fileImg && (
                                <div className='flex flex-col items-center'>
                                    <p>{dataFiles.fileImgName}</p>
                                    <p>KB {Math.round(dataFiles.fileImg.size / 1024)}</p>



                                </div>

                            )}
                            <div className="w-full pt-2 grid grid-cols-10">


                                <label htmlFor="urlimage" className='grid col-span-3'><p ><span className='text-red-600'>*</span>חתימה (הקישור)</p></label>
                                <input
                                    className='border grid col-span-5 text-right bg-slate-300'
                                    type="text"
                                    id="urlimage"
                                    name="urlimage"
                                    value={dataFiles?.fileImg ? previewUrl : ""}
                                // disabled
                                />
                            </div>
                            <div className='flex flex-col items-center'>
                                <img src={previewUrl} alt="Preview" className='border-2 border-black my-2' style={{
                                    width: '100px', height: '100px',
                                    // objectFit: 'cover'
                                }} />
                            </div>
                        </div>



                        <div className='border-2 border-black bg-[#ccc]  rounded-md my-2 py-2 px-1'>
                            <button type="button" className='flex text-[10px] w-fit place-self-center py-1 px-2 items-baseline bg-gray-400  border-black border my-2 rounded-sm text-white cursor-pointer' onClick={() => handleTakePhotoClick("fileInputRef")}>
                                <FaUpload />
                                <p className='mr-2'>העלה קובץ</p>
                            </button>
                            <input type="file" id="fileInput" name="fileInput"
                                onChange={handleFileChange}
                                accept="application/pdf"
                                style={{ display: 'none' }}
                                ref={fileInputRef}
                            />

                            {dataFiles.file && (
                                <div className='flex justify-center w-full'>
                                    <div className='flex flex-col bg-white w-1/2 px-4 '>
                                        <p>{dataFiles.fileName}</p>
                                        <p>KB {Math.round(dataFiles.file.size / 1024)}</p>
                                    </div>
                                </div>

                            )}
                            <div className="w-full pt-2 grid grid-cols-10">


                                <label htmlFor="urlimage" className='grid col-span-3'><p >אישור כשירות<span className='text-red-600'>*</span></p></label>
                                <input
                                    className='border grid col-span-5 text-right bg-slate-300'
                                    type="text"
                                    id="urlimage"
                                    name="urlimage"
                                    value={dataFiles.fileName}
                                    disabled
                                />
                            </div>

                        </div>
                        <div className='border-2 border-black bg-[#ccc]  rounded-md  py-2 px-1'>
                            <button type="button" className='flex text-[10px] w-fit place-self-center py-1 px-2 items-baseline bg-gray-400  border-black border my-2 rounded-sm text-white cursor-pointer' onClick={() => handleTakePhotoClick("signInputRef")}>
                                <FaUpload />
                                <p className='mr-2'>העלה קובץ</p>
                            </button>
                            <input type="file" id="fileInput" name="fileInput"
                                onChange={handleImgChange}
                                style={{ display: 'none' }}
                                ref={signInputRef}

                            />

                            {dataFiles?.fileImgName && (
                                <div className='flex flex-col items-center'>
                                    <p>{dataFiles?.fileImgName}</p>
                                    <p>KB {Math.round(dataFiles?.fileImg?.size / 1024)}</p>



                                </div>

                            )}
                            <div className="w-full pt-2 grid grid-cols-10">


                                <label htmlFor="urlimage" className='grid col-span-3'><p ><span className='text-red-600'>*</span>חתימה (הקישור)</p></label>
                                <input
                                    className='border grid col-span-5 text-right bg-slate-300'
                                    type="text"
                                    id="urlimage"
                                    name="urlimage"
                                    value={dataFiles?.fileImg ? previewUrl : ""}
                                    disabled
                                />
                            </div>
                            <div className='flex flex-col items-center'>
                                <img src={previewUrl} alt="Preview" className='border-2 border-black my-2' style={{
                                    width: '100px', height: '100px',
                                    // objectFit: 'cover'
                                }} />
                            </div>
                        </div>
                    </div>



                </form>
                <div className='w-full p-2   flex justify-end'>
                    <button className='border bg-green-500 hover:bg-green-300 p-1 px-3' onClick={() => {
                        // if (nameRole != "") {
                        // row ? handleEditRole(row.id) :
                        addNewMember()
                        // }


                    }}>שמור</button>
                    <button className='border bg-slate-700 hover:bg-slate-500 p-1 px-3' onClick={() => {
                        // reset()
                        // setShowModal(false)
                        handleColse()
                    }}>בטל</button>
                </div>
            </div>
        </div>
    );
};


export default ModalNewMembr