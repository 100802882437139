import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { IoIosLogOut } from "react-icons/io";
import { TbUsersPlus } from "react-icons/tb";
import { FaGraduationCap } from "react-icons/fa";
import { FaCogs } from "react-icons/fa";
import { FaHome } from "react-icons/fa";
import { IconContext } from 'react-icons';
import ModalLogut from './ModalLogut';
import Cookies from "universal-cookie"

const MainNavigation = () => {
    const cookie = new Cookies()
    const role = cookie.get('role')==="ROLE_MEMBER"?true:false;
    const handleColse=()=>{
        setOpen(false)
    }
    const [open,setOpen]=useState(false)
    return (
        <header className='grid grid-cols-3 bg-[#0d406b] ' >
            <ul className='grid grid-cols-3  list-none'>
                <li className="hover:bg-green-500 "> 
                    <NavLink to="" className={({ isActive }) => (isActive ? 'bg-green-400 flex text-white items-center justify-center p-2' : 'flex text-white items-center justify-center p-2')} >
                        דף הביתה
                        <FaHome className='ml-1'/>
                    </NavLink>
                </li>
                {role&&<li className="hover:bg-green-500 ">
                    <NavLink to="registrationTrainingDays" className={({ isActive }) => (isActive ? 'bg-green-400 flex text-white items-center justify-center py-2' : 'flex text-white items-center justify-center py-2')}>
                        הרשמה לימי כשירות
                    <FaGraduationCap className='ml-1'/>
                    </NavLink>
                </li>}
                {!role&&<li className="hover:bg-green-500 ">
                    <NavLink to="Adminstration" className={({ isActive }) => (isActive ? 'bg-green-400 flex text-white items-center justify-center p-2' : 'flex text-white items-center justify-center p-2')}>
                        מנהל מערכת
                        <IconContext.Provider value={{}}>
                        <FaCogs className='ml-1'/>
                        </IconContext.Provider>
                    </NavLink>

                </li >}
            </ul >

            <ul className='grid  col-start-3 grid-cols-3  list-none  '  >
                {!role&&<li className="hover:bg-green-500 ">
                    <NavLink to="/tranings" className={({ isActive }) => (isActive ? 'bg-green-400 flex text-white items-center justify-center p-2' : 'flex text-white items-center justify-center p-2')}>
                        ימי כשירות
                        <FaGraduationCap className='ml-1'/>
                        
                    </NavLink>

                </li >}
                {!role&&<li className="hover:bg-green-500 ">
                    <NavLink to="/members" className={({ isActive }) => (isActive ? 'bg-green-400 flex text-white items-center justify-center p-2' : 'flex text-white items-center justify-center p-2')} >
                        חברי הלשכה
                        <TbUsersPlus className='ml-1'/>
                    </NavLink>

                </li>}
                <li className="hover:bg-green-500 grid  col-start-3" onClick={()=>{setOpen(true)}} >
                    
                    <div className='flex text-white items-center justify-center p-2'>
                        התנתק
                        <IoIosLogOut className='ml-1'/>
                    </div>

                </li>

            </ul>
            {open&&<ModalLogut showModal={open} handleColse={handleColse}> </ModalLogut>}
        </header>
    )
}

export default MainNavigation