import React from 'react'

const Input = ({ label, id, error, ...props }) =>  {
    return (
      <div className="w-full">
        <div className='w-full flex justify-end mb-2'>
        <input id={id} {...props} />
        <label className='w-[30%] pl-2' htmlFor={id}>{label}</label>
        </div>
        <div className="control-error">{error && <p>{error}</p>}</div>
      </div>)
}

export default Input



// <div className="w-full">
//         <div className="w-full pt-2 flex justify-end">

//             <input id={id} {...props} />
//             <label className='w-[30%]' htmlFor={id}>{label}</label>
//             {/* <div className="control-error">{error && <p>{error}</p>}</div> */}
           
//         </div>
//         <div className="text-[#ffca99]
//         text-[0.8rem]
//         height-[2rem];
//         padding-[0.5rem 0]">{error && <p>{error}</p>}</div></div>



// <div className="control no-margin">
//         <label htmlFor={id}>{label}</label>
//         <input id={id} {...props} />
//         <div className="control-error">{error && <p>{error}</p>}</div>
//       </div>