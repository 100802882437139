import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { BsPencil } from "react-icons/bs";
import { IoTrashOutline } from "react-icons/io5";
import axios from 'axios'
import ButtonNew from '../../ButtonNew';

import ModalNewGuides from './ModalNewGuides';
import ModalEdit from '../../ModalEdit';
import GuidesFilter from './GuidesFilter';
const GET_URL = 'admin/qualification/instructor'


const Guides = ({setHide}) => {
    const [modalIsOpen, setModalIsOpen] = useState("");
    const [id, setId] = useState("");
    const [listClass, setlistClass] = useState([])
    const [loading, setloading] = useState(false)
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [dataFillter, setDataFillter] = useState({
        'name': '',
        'identity': '',
        'phone': ''
    })



    const handleColse = () => {
        setModalIsOpen("")
    }

    const handleDetelePaying_customer = async () => {

        await axios.delete(`${GET_URL}/${id.id}`).then(response => {
            if (response.status == 200) {
                handleColse()
                getPaying_customers(currentPage - 1)
                console.log("ok")
            }
            else {
                console.log("error")
            }
        })
    }


    const handlePageChange = (page) => {
        // fetchUsers(page);
        getPaying_customers(page);
        setCurrentPage(page);
    };


    const handlePerRowsChange = async (newPerPage) => {
        // setloading(true);


        // setData(response.data.items);
        setPerPage(newPerPage);
        // setTotalRows(response.data.totalCount);
        // setloading(false);
    }




    const getPaying_customers = async (page) => {
        // setloading(true);
        // await axios.get(`${GET_PAYING_CUSTOMERS_URL}s`).then(response => {
        await axios.get(GET_URL, {
            params: {
                "identity": dataFillter.identity
                , "name": dataFillter.name,
                'phone': dataFillter.phone
                , "page": page
                , "size": perPage,
                "sortBy": "name"
            }
        })
            .then(response => {
                setlistClass(response?.data?.instructorDtoList)
                setTotalRows(response.data?.totalElements);
            })
        setloading(false)
        console.log(listClass)
    }



    useEffect(() => {
        getPaying_customers(currentPage - 1)
    }, [currentPage, perPage, dataFillter])


    useEffect(() => {
        if (modalIsOpen) {
            document.body.style.overflow = 'hidden'; // Disable body scrolling
          } else {
            document.body.style.overflow = 'auto'; // Enable body scrolling
          }
      
          return () => {
            document.body.style.overflow = 'auto'; // Cleanup: ensure body scroll is reset
          };
        // setHide(modalIsOpen)
    }, [modalIsOpen])

    const columns = [
        {
            name: "פעולות",

            cell: row => <div><button className=' border border-gray-500 p-2 opacity-15 hover:opacity-100' onClick={() => {
                // setnamecalss(row?.description)
                // setAction("edit")
                setId(row)
                setModalIsOpen("Edit")
            }
            }><BsPencil /></button>
                <button title="מחיקה" className=' border border-gray-500 ml-2 p-2 opacity-15 hover:opacity-100' onClick={() => {
                    setId(row)
                    setModalIsOpen("Delete")
                }}><IoTrashOutline /></button> </div>,
            style: {
                fontSize: '10px',
                justifyContent: 'center',

            },
            grow: 1,


        },
        {
            name: "חתימה",
            grow: 1,
            selector: (row) => row?.imgSignature ? <img src={`https://archivebureau-tests.s3.eu-west-3.amazonaws.com/${row?.imgSignature}`} className='w-[80px]' /> : "",
            style: {
                fontSize: '16px',
                justifyContent: 'center',
                width: '100px'
            },
            sortable: true,
        },
        {
            name: "טלפון",
            grow: 3,
            selector: (row) => row?.phone,
            style: {
                fontSize: '16px',
                justifyContent: 'center',
                width: '100px'
            },

        },
        {
            name: "שם מלא",
            grow: 3,
            selector: (row) => row?.name,
            style: {
                fontSize: '16px',
                justifyContent: 'center',
                width: '100px'
            },
            sortable: true,
        },
        {
            name: "ת.ז",
            grow: 3,
            selector: (row) => row?.identity,
            style: {
                fontSize: '16px',
                justifyContent: 'center',
                width: '100px'
            },
            sortable: true,
        },
    ];



    const customStyles = {
        header: {
            style: {

                justifyContent: 'center',

            },
        },
        id: {
            style: {
                '&:2': { backgroundColor: '#EEEEEE' },
            }
        },
        headRow: {
            style: {
                justifyContent: 'center',
                borderTopStyle: 'solid',
                borderTopWidth: '1px',
                // borderTopColor: defaultThemes.default.divider.default,
            },
        },
        headCells: {
            style: {

                justifyContent: 'center',
                borderRightStyle: 'solid',
                borderRightWidth: '1px',
                // borderRightColor: defaultThemes.default.divider.default,

            },
        },
        cells: {
            style: {
                '&:not(:last-of-type)': {

                    borderRightStyle: 'solid',
                    borderRightWidth: '1px',
                    // borderRightColor: defaultThemes.default.divider.default,
                },
            },
        },
        rows: {
            style: {
                backgroundColor: '#FFFFFF',
                '&:active': {
                    backgroundColor: '#EEEEEE',
                },
            },
        },
    }
    return (
        <div className='w-full p-3'>
            <GuidesFilter setlistClass={setlistClass}
                //   dataFillter={dataFillter} 
                setDataFillter={setDataFillter}
                //    getPaying_customers={getPaying_customers} 
                setCurrentPage={setCurrentPage} />
            <ButtonNew active={"מדריכים"} setModalIsOpen={setModalIsOpen} />
            <DataTable columns={columns} data={listClass} pagination
                customStyles={customStyles}
                paginationServer
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                progressPending={loading} className='border' >

            </DataTable>
            {modalIsOpen == "New" && <ModalNewGuides showModal={modalIsOpen == "New"} handleColse={handleColse} getDepartment={getPaying_customers}>
            </ModalNewGuides>}
            {modalIsOpen == "Edit" && <ModalNewGuides showModal={modalIsOpen == "Edit"} handleColse={handleColse} getDepartment={getPaying_customers} row={id}>
            </ModalNewGuides>}
            {modalIsOpen == "Delete" && <ModalEdit showModal={modalIsOpen == "Delete"} handleColse={handleColse} handleDetele={handleDetelePaying_customer}></ModalEdit>}

        </div>
    )
}

export default Guides