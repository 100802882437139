import React, {useState } from 'react'
import { IconContext } from 'react-icons';
import { TfiKey } from "react-icons/tfi";
import { FaRegUser } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
import Cookies from "universal-cookie"
import axiosAuth from '../Api/axiosAuth';
import { useAuth } from '../Context/AuthProvider';
const LOGIN_URL = '/auth/login';

const CardLogin = () => {
    const [infpError,setInfpError]=useState(false);
    const [user,setUser]=useState("");
    const [password,setPassword]=useState("");
    const navigate=useNavigate();
    const cookies = new Cookies();
    const { auth ,  setAuth } = useAuth()
    const auth_submit = async(e) => 
    {   e.preventDefault()
        try
        {
            const response = await axiosAuth.post(LOGIN_URL , {
                'userName':user, 'password': password
            }
             , {'Access-Control-Allow-Credentials': true});
            
             cookies.set('bezkoder-jwt-refresh' , response?.data['refersh_token'] ,  { path: '/'  , maxAge: 31536000} ,   {httpOnly: true}, {SameSite:"None"})
             cookies.set('role' , response?.data['roles'][0] ,  { path: '/'  , maxAge: 31536000} ,   {httpOnly: true}, {SameSite:"None"})
             axiosAuth.defaults.headers.common['Authorization'] = `Bearer ${response?.data['access_token']}`;
             setAuth( response?.data['refersh_token'] )
             setPassword('')
             setUser('')
             setInfpError(false)
             navigate('/') 
             
         }
         catch(err)
         {
            setInfpError(true)
         }

    }
//     if(user=="123" && password =="0000")
//     {
//         navigate('/') 
//        console.log(true)
//         setInfpError(false)
// }   else
//         {
//             setInfpError(true)
//             console.log("false")
//         }


    return (
        <form className='bg-white flex flex-col w-[400px]  max-sm:w-[80%] border-2 border-solid border-transparent rounded-md  mt-10  'onSubmit={auth_submit} style={{ direction: "rtl" }}>

            <label className=' bg-[#F7F7F7] w-full py-[1rem] text-[22px] font-normal'  > התחברות</label>
            <div className=' p-8'>
                {infpError&&<p className='mt-0 text-red-700'>המשתמש או הסיסמה לא נכונים</p>}
                <div className='flex w-full '>
                <IconContext.Provider value={{ color: "#555",size:35}}>
                    <FaRegUser className='bg-[#eee] border-[1px] rounded-sm  border-solid p-2 border-[#ccc]'/>
                    </IconContext.Provider>
                
                    <input className='w-full border-[1px] rounded-sm  border-solid  border-[#ccc]' type="text" placeholder='שם משתמש' onChange={(e)=>{setUser(e.target.value)}} value={user} />
                </div>

                <div className='flex mt-4' >
                    <IconContext.Provider value={{ color: "#555",size:35}}><TfiKey className='bg-[#eee] border-[1px] rounded-sm  border-solid p-2 border-[#ccc]'/></IconContext.Provider>
                     
                    <input type="password" placeholder='סיסמה' className='w-full border-[1px] rounded-sm  border-solid  border-[#ccc]' onChange={(e)=>{setPassword(e.target.value)}} value={password}/>
                </div>
                <button className=' bg-[#0D406A] w-full text-lg rounded border-[#0a3254] hover:bg-[#07253e] hover:border-[#04121e] p-2 mt-8  text-white'>התחבר</button>
            </div>
        </form>

    )
}

export default CardLogin