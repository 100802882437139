import React, { useEffect, useRef} from 'react'
import { createPortal } from 'react-dom'
const Modall = ({ children, showModal}) => {
  const dialog = useRef()
  useEffect(() => {
    if (showModal) {
      dialog.current.showModal()
    }
    
    // return ()=>dialog.current.close()
    
  }
    , [showModal])


   

  //[-2px 5px 5px #FDFFFC]
  return (createPortal(
    <dialog ref={dialog} className=' p-0 w-1/2'>
    <div className='flex flex-col items-center justify-center  border border-1 border-[#011627] rounded-2xl shadow-md'>
     {/* <dialog ref={dialog} className='modal'> */}
      {children}
      </div>
    {/* </dialog>, document.getElementById('modal')) */}
    </dialog>, document.getElementById('modal'))
  )
}




export default Modall




// זה בשביל להעלים את SCROOL 
// style={{ "-ms-overflow-style": "none" 
//   ,"scrollbar-width": "none" }}