import React from 'react'
import ramdor_logo from '../assets/logoOz.png'
import ismb_logo from '../assets/ismb_logo.png'

const Header = () => {
  return (
    <header className='grid-cols-3 gap-3 items-center justify-items-center grid bg-[#0d406b] pb-3 mb-1'>
        <div className="text-white">
            עזרה
        </div >
        <img src={ismb_logo} alt='ismb_logo' className=" w-[130px] "/>
        <img  src={ramdor_logo} alt='ramdor_logo' className=" "/>

    </header>
  )
}

export default Header