import {createContext , useContext ,useEffect, useState} from "react"
import Cookies from "universal-cookie"
import axios from "axios"
import { Navigate, redirect } from "react-router-dom"

const AuthContext = createContext({})

export const AuthProvider = ({ children }) =>
{
    const cookies = new Cookies();
    const load = cookies.get('bezkoder-jwt-refresh'); 
    const [auth , setAuth]  = useState(load ? cookies.get('bezkoder-jwt-refresh') : null)

    useEffect(() => {
        setAuth(cookies.get('bezkoder-jwt-refresh')?cookies.get('bezkoder-jwt-refresh'):null)
      }, [cookies]);

    const login = (loginAuth) => 
    {
        // cookies.set('bezkoder-jwt-refresh' , JSON.stringify(loginAuth['refersh_token']) ,  { path: '/'  , maxAge: 31536000})
        // axios.defaults.headers.common['Authorization'] = `Bearer ${loginAuth['access_token']}`;
      
    }
    const logout = async() => 
    {

        // setSite_id(null)
        // cookies.remove('save_site')

        // const refershToken = cookies.get('bezkoder-jwt-refresh')
        // console.log(refershToken)
        // const {data} = await axios.post('signout' , {"token":refershToken});
        // cookies.remove('bezkoder-jwt-refresh')
        // axios.defaults.headers.common['Authorization'] = null;
        // console.log(data?.message)
        // cookies.remove('wonderPush_token')
        // return redirect ("/login") 
    }
    // const checkAuth=()=>{
    //     const cookies = new Cookies();
    //     const load = cookies.get('bezkoder-jwt-refresh'); 
    //     if(!load)
    //         {
    //             //  <Navigate to="/login" />
    //             redirect("/login")  
    //         }
    // }

    return (
        <AuthContext.Provider value={{auth , setAuth , login , logout,
        // checkAuth
        }}>
            {children} 
        </AuthContext.Provider>
    )
}
export default AuthContext;


export const useAuth = () => useContext(AuthContext);
