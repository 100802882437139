import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { BsPencil } from "react-icons/bs";
import { IoTrashOutline } from "react-icons/io5";
import { MdAssignment } from "react-icons/md"
import { MdAssignmentReturned } from "react-icons/md"

import axios from 'axios'
import ButtonNew from '../ButtonNew';
import ModalNewTrainingDays from '../tranings/trainingdays/ModalNewTrainingDays';
import ModalEdit from '../ModalEdit';
import MemberFilter from './MemberFilter'
import ModalNewMembr from './ModalNewMembr';
import MoreOptions from './MoreOptions';

const GET_URLDELETE = 'admin/member/delete_member'
const GET_URL = 'admin/member/get_members'
const GET_URLFORFILTER = 'admin/member/init_filter_member'


const Member = ({setHide}) => {
    const [modalIsOpen, setModalIsOpen] = useState("");
    const [id, setId] = useState("");
    const [dataforfilter, setDataforfilter] = useState("");
    const [fetchData, setFetchData] = useState([])
    const [loading, setloading] = useState(true)
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1)
    // const [dataFillter, setDataFillter] = useState({
    //     "firstName": "",
    //         "lastName": "",
    //         "memberIdNumber": "",
    //         "memberNumber": "",
    //         "trainingName": "",
    //         "trainingNumber": "",
    //         "trainingDate": "",
    //         "trainingStatusName": "",
    //         "trainingClusterName": "",
    //         "clusterNumber": "9",
    //         "clientName": "",
    // })
    const [dataFillter, setDataFillter] = useState({
        "firstName": "",
        "lastName": "",
        "idNumber": "",
        "statusId": "",
        "pay_id": "",
        "pay_id_labal": "",
        "dateEn": "",
        "number": "",
        "dateSt": "",
    })
    // const [dataForfilterScrach, setdataForfilterScrach] = useState([])
    const getTrainingDays = async (page) => {
        setloading(true)
        await axios.get(GET_URL, {
            params: {
                ...dataFillter,
                "page": page
                , "size": perPage,



            }
        })
            .then(response => {
                if (response.status == 200) {
                    setFetchData(response?.data?.resMemberDisplayList)
                    // setdataForfilterScrach(response?.data)
                    setTotalRows(response.data.totalElements);
                }
            })
        setloading(false)

    }
    const getDataFILTER = async () => {

        await axios.get(GET_URLFORFILTER, {
        })
            .then(response => {
                setDataforfilter(response?.data)

            })

    }

    const handleDetele = async () => {

        await axios.delete(`${GET_URLDELETE}/${id.id}`).then(response => {
            if (response.status == 200) {
                handleColse()
                getTrainingDays()
                console.log("ok")
            }
            else {
                console.log("error")
            }
        })
    }

    useEffect(() => {
        getTrainingDays(currentPage - 1)
    }, [currentPage, perPage, dataFillter])

    const handlePerRowsChange = async (newPerPage) => {
        setPerPage(newPerPage);

    }

    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     setDataFillter({
    //         ...dataFillter,
    //         [name]: value
    //     });
    // }
    const handlePageChange = (page) => {
        // fetchUsers(page);
        // getData(page);
        setCurrentPage(page);
    };

    const handleColse = () => {
        setModalIsOpen("")
    }

    useEffect(() => {
        // getTrainingDays(currentPage - 1)
        getDataFILTER(currentPage - 1)
    }, [])

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2); // Ensure 2 digits for month
        const day = ('0' + date.getDate()).slice(-2); // Ensure 2 digits for day
        return `${day}/${month}/${year}`;
    };
    const columns = [
        {
            name: "פעולות",

            cell: row => <div className='w-full'>
                <a title="ייצא תעודה"  className='p-0'
                        href={`https://archivebureau-tests.s3.eu-west-3.amazonaws.com/${row.imgCertificate}`}
             ><button className=' border border-gray-500 p-1 opacity-25 hover:opacity-100' ><MdAssignmentReturned /></button></a>
                <button title="עוד אפשרויות" className=' border border-gray-500 ml-1 p-1 opacity-25 hover:opacity-100' onClick={() => {
                    setId(row)
                    setModalIsOpen("MoreOptions")
                }}><MdAssignment /></button>
                <button title="מחיקה" className=' border border-gray-500 ml-1 p-1 opacity-25 hover:opacity-100' onClick={() => {
                    setId(row)
                    setModalIsOpen("Delete")
                }}><IoTrashOutline /></button>
                <button className=' border border-gray-500 p-1  ml-1 opacity-25 hover:opacity-100' onClick={() => {
                // setnamecalss(row?.description)
                // setAction("edit")
                setId(row)
                setModalIsOpen("Edit")
            }
            }><BsPencil /></button>
            </div>,


            style: {
                fontSize: '10px',
                justifyContent: 'center',



                // padding: '2px'
                // width: '100%'
            },
            grow: 1,


        },
        {
            name: "לקוח משלם",
            grow: 2,
            selector: (row) => row.displayPaying,
            style: {
                fontSize: '9px',
                justifyContent: 'center',
                width: '100px'
            },
            sortable: true,
        },

        {
            name: "סטטוס",
            // grow: 3,
            selector: (row) => row?.status?.statusDescription,
            style: {
                fontSize: '10px',
                justifyContent: 'center',
                // width: '100px'
            },
            sortable: true,
        },
        {
            name: "תאריך הרשמה",
            grow: 1,
            selector: (row) => formatDate(row?.dateRegister),
            style: {
                fontSize: '10px',
                justifyContent: 'center',
                // width: '500px'
            },
            sortable: true,
        },
        {
            name: "שם משפחה",
            // grow: 3,
            selector: (row) => row?.lastName,
            style: {
                fontSize: '9px',
                justifyContent: 'center',
                // width: '100px'
            },
            sortable: true,
        },
        {
            name: "שם פרטי",
            // grow: 3,
            selector: (row) => row?.firstName,
            style: {
                fontSize: '9px',
                justifyContent: 'center',
                // width: '100px'
            },
            sortable: true,
        },
        {
            name: "ת.ז",
            // grow: 3,
            selector: (row) => row?.idNumber,
            style: {
                fontSize: '9px',
                justifyContent: 'center',
                // width: '100px'
            },
            sortable: true,
        },


        {
            name: "מספר חבר",
            // grow: 3,
            selector: (row) => row?.number,
            style: {
                fontSize: '9px',
                justifyContent: 'center',
                // width: '100px'
            },
            sortable: true,


        },



    ];

useEffect(()=>{
    setHide(modalIsOpen)
},[modalIsOpen])

    const customStyles = {
        header: {
            style: {

                justifyContent: 'center',

            },
        },
        id: {
            style: {
                '&:2': { backgroundColor: '#EEEEEE' },
            }
        },
        headRow: {
            style: {
                justifyContent: 'center',
                borderTopStyle: 'solid',
                borderTopWidth: '1px',
                // borderTopColor: defaultThemes.default.divider.default,
            },
        },
        headCells: {
            style: {

                // whiteSpace: 'normal',
                // overflow: 'visible',
                // textOverflow: 'clip',
                // maxWidth: 'none', // Allow headers to expand as needed
                // padding: '10px'
                // ,
                fontSize: '10px',
                justifyContent: 'center',
                borderRightStyle: 'solid',
                borderRightWidth: '1px',
                paddingLeft: '0px',
                paddingRight: '0px'
                // borderRightColor: defaultThemes.default.divider.default,

            },
        },
        cells: {
            style: {
                '&:not(:last-of-type)': {
                    // padding: '10px'
                    // ,
                    //       paddingLeft:'0px',
                    // paddingRight:'0px',
                    //     borderRightStyle: 'solid',
                    //     borderRightWidth: '1px',
                    // borderRightColor: defaultThemes.default.divider.default,
                },
                borderRightStyle: 'solid',
                borderRightWidth: '1px',
                paddingLeft: '0px',
                paddingRight: '0px',
            },
        },
        rows: {
            style: {
                backgroundColor: '#FFFFFF',
                '&:active': {
                    backgroundColor: '#EEEEEE',
                },
            },
        },
    }
    return (
        <div className='bg-[#f3f3f3] p-4' >
            <MemberFilter 
                dataforFILTER={dataforfilter}
                setDataFillterout={setDataFillter} />

            <ButtonNew active={"חברי הלשכה"} setModalIsOpen={setModalIsOpen} />
            <DataTable columns={columns} data={fetchData} pagination customStyles={customStyles}
                paginationServer
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                progressPending={loading} className='border' >
            </DataTable>
            {modalIsOpen == "New" && <ModalNewMembr showModal={modalIsOpen == "New"} handleColse={handleColse} getDepartment={getTrainingDays} 
            getDataFILTER={getDataFILTER}
            >
            </ModalNewMembr>}
            {modalIsOpen == "Edit" && <ModalNewMembr showModal={modalIsOpen == "Edit"} handleColse={handleColse} getDepartment={getTrainingDays} row={id}>
            </ModalNewMembr>}
            {modalIsOpen == "Delete" && <ModalEdit showModal={modalIsOpen == "Delete"} handleColse={handleColse} handleDetele={handleDetele}></ModalEdit>}
            {modalIsOpen == "MoreOptions" && <MoreOptions handleColse={handleColse} row={id}></MoreOptions>}

        </div>
    )
}

export default Member