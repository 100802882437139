import React, { useState, useEffect } from 'react'
import axios from 'axios'
import ReDataTable from '../../ui/ReDataTable';
import { HeaderTable } from '../../HeaderTable';

const MembersTrainingdays = ({row}) => {
    const GET_URL=`admin/member/get_qualification_days_member/${row?.id}`
    const getData = async (page,perPage) => {
        const response= await axios.get(GET_URL, {
            params: {
                "page": page
                , "size": perPage,
            }
        })
            if (response.status=="200") 
             {

                const response1 = (response?.data?.listQualifications)
                const response2= (response?.data?.totalElements);
                return [response1, response2];
            }
            const response1 = []
            const response2= []
            return [response1, response2];
      };

    const columns = [
       
       
        {
          name: "סטטוס",
          grow: 2,
          selector: (row) => row?.qualificationDay?.statusQualification?.statusDescription,
          style: {
            fontSize: '16px',
            justifyContent: 'center',
            width: '100px'
          },
          sortable: true,
        },
        {
            name: "מיקום",
            grow: 2,
            selector: (row) => row?.qualificationDay?.place,
            style: {
              fontSize: '16px',
              justifyContent: 'center',
              width: '100px'
            },
            sortable: true,
          },
        {
            name: "תאריך",
            grow: 2,
            selector: (row) => row?.qualificationDay?.startDate,
            style: {
              fontSize: '16px',
              justifyContent: 'center',
              width: '100px'
            },
            sortable: true,
          },
        {
            name: "שם יום הכשירות",
            grow: 2,
            selector: (row) => row?.qualificationDay?.name,
            style: {
              fontSize: '16px',
              justifyContent: 'center',
              width: '100px'
            },
            sortable: true,
          },
        {
            name: "שם אשכול",
            grow: 2,
            selector: (row) => row?.qualificationDay?.cluster?.name,
            style: {
              fontSize: '16px',
              justifyContent: 'center',
              width: '100px'
            },
            sortable: true,
          },
        {
            name: "מספר אשכול",
            grow: 2,
            selector: (row) => row?.qualificationDay?.cluster?.number,
            style: {
              fontSize: '16px',
              justifyContent: 'center',
              width: '100px'
            },
            sortable: true,
          },
    
      ];

  return (
    <div style={{direction:"ltr"}} >
        <HeaderTable active={"ימי כשירות"}/>
             <ReDataTable columns={columns} getData={getData}/>
             {/* {modalIsOpen=="New"&&<NewActivityday handleColse={handleColse}>

             </NewActivityday>} */}
        </div>
  )
}

export default MembersTrainingdays